import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { 
    Col,
    Container,
    Row,
    Input,
    Label, } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import {useParams} from 'react-router-dom';


import AssetsListService from '../../../api/service/AssetsListService';
import EmissionFactorService from '../../../api/service/EmissionFactorService';
import EmbeddedLinkTenant from '../../../api/service/EmbeddedLinkTenant';
import TenantService from '../../../api/service/TenantService';

const EditEmbeddedLink = () => {
  document.title = "Assets List | Carbon Platform";
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initCreate = {
    name:"",
    link:"",
    page_destination :"",
    tenant_id:"",
  }
  const initEmbeddedLink = {
    id: "",
    name: "",
    link: "",
    page_destination: "",
    updated_by: "",
    updated_at: "",
    tenant_id: "",
    tenant_name: ""
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);

  // const [body1, setbody1] = useState(initCreate);
  const [ListPageDestination, setListPageDestination] = useState([]);
  const [Tenant, setTenant] = useState([initTenant]);
  const listDestination = [
    "Custom",
    "Emission Type",
    "Monthly",
    "Summary"
  ]
  var tenant_user = localStorage.getItem('tenant_id') || "";
  const [body, setbody] = useState(initCreate);
  const [body_rl, setbody_rl] = useState("");
  const {id} = useParams();
  const navigate = useNavigate();
  const factor_unit = ["GWP","kgN2O/kgNremoved","kgCH4/kgCODremoved","kgCH4/kgBODremoved","tCO2/L","tCO2/kWh","tCO2eq/kg"];

  useEffect(() => {
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    
    EmissionFactorService.getId(id)
    .then(response => {
      
      setbody(response.data.data)
      
    })
    .catch(e => {
      console.log(e);
    });
    EmbeddedLinkTenant.getId(id)
    .then(response => {
        setbody(response.data.data)
    })
    .catch(e => {
        console.log(e);
    });
    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));

    EmbeddedLinkTenant.getPageDestination(tenant_user)
    .then(res => {
      setListPageDestination(res.data.data.destination);
      
    }).catch(err => console.log(err));
  }, [])
  


  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbody({ ...body, [name]: value });
  };
  const save = ()=> {
    var data = {
        name : body.name,
        link : body.link,
        page_destination: body.page_destination,
        tenant_id: body.tenant_id,
      
    }
    console.log(data)
    EmbeddedLinkTenant.update(id,data)
    .then(response => {
      swal("Saved!", "Your data succes Updated!", "success");
      navigate("/embeded-link");
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Emission Factor" link_title="/emission-factor" breadcrumbItem="Edit Emission Factor" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Name</Label>
            <Input 
              className="form-control"
              placeholder="Name"
              type="text" 
              id="name"
              name="name"
              value={body.name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Page Destination</Label>
            <select 
            value={body.page_destination} 
            onChange={handleInputChange}
            id="page_destination"
            name="page_destination"
            className="form-select">
                <option value={body.page_destination}>{body.page_destination}</option>
                {
                    ListPageDestination?.map((raw, index)=>(
                    <option 
                        key={index} 
                        value={raw}
                        // onChange={Handlefilter}
                        >{raw}
                    </option>

                    ))
                }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Link</Label>
            <Input 
            className="form-control"
            placeholder="https://..."
            type="text" 
            id="link"
            name="link"
            value={body.link}
            onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>

            <select 
            value={body.tenant_id} 
            onChange={handleInputChange}
            id="tenant_id"
            name="tenant_id"
            className="form-select">
                <option value="">Select Tenant</option>
                {
                    Tenant?.map((raw, index)=>(
                    <option 
                        key={raw.id} 
                        value={raw.id}
                        >{raw.name}
                    </option>

                    ))
                }
            </select>
          </div>
          <div className="text-sm-end">
            <Link to={"/embeded-link"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            <button 
              type="button"
              onClick={() => {
                save();
              }}
              className="btn btn-success">
              Update
            </button>
          </div>
          <br />
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditEmbeddedLink;
