import React,  {useEffect, useMemo, useState} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  Input,
  Label,
 } from "reactstrap";

import moment from "moment";
import type { DatePickerProps } from 'antd';
import { DatePicker, Space, Flex } from 'antd';
// import { DatePicker } from 'rsuite';
import ReactPaginate from "react-paginate"; 
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import EmissionFactorService from '../../../api/service/EmissionFactorService';
import AssetsListService from '../../../api/service/AssetsListService';
import IndustrialService from '../../../api/service/IndustrialService';
import TenantService from '../../../api/service/TenantService';
import ThresholdSettingService from '../../../api/service/ThresholdSettingService';

import useObjectState from "../../../api/service/useObjectState";


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import { mapKeys } from "lodash";
import { Fragment } from "react";
import { ar, tr } from "date-fns/locale";
import { Th } from "react-super-responsive-table";

const EmissionFactorPage = () => {
  document.title = "Threshold Setting | Carbon Platform";
  const initEmissionFactor = {
    id :"",
    name : "",
    type : "",
    emission_factor_value :"",
    emission_factor_unit :"",
    reference:"",
    reference_link:"" 
  }
  const defaultMonth = {
    month_id:"",
    month : "",
    lower_threshold : 0,
    upper_threshold : 0,
    unit : ""
  }
  const initCreate = {
    industrial_id:"",
    tenant_id : "",
    area_id:"",
    year :"", undefined,
    threshold_month : [defaultMonth],
    name:"",
    type:"",
    emission_factor_value:0,
    emission_factor_unit:"",
    reference:"",
    reference_link:""
  }
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  const initMonthThreshold = {
    threshold_id:"",
    date_month : "",
    lower_threshold : 0,
    upper_threshold : 0,
    unit : ""
  }
  const initThresholdSetting = {
    year : "",
    area_id : "",
    monthly_threshold : [initMonthThreshold]
  }
  const dummy =[
    {
        id : Math.random(),
        industrial_park : "test IP",
        tenant : "test tenant",
        year : "2023",
        target_month : [
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            },
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            },
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            }
        ]
    },
    {
        id : Math.random(),
        industrial_park : "test IP 2",
        tenant : "test tenant2",
        year : "2024",
        target_month : [
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            },
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            },
            {
                month : "Jan",
                lower : 1200,
                upper : 4000,
                unit : "Ton/abc"
            }
        ]
    },
  ]
  const [EmissionFactor, setEmissionFactor] = useState([initEmissionFactor]);
  const [SettingThreshold, setSettingThreshold] = useState([initThresholdSetting]);
  const [types, setTypes] = useState([]);
  const [filter_type , setFiltertype] = useState("")
  const [filter_area , setFilterarea] = useState("")
  const [filter_group , setFiltergroup] = useState("")
  const [search_name , setSearchName] = useState("")
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [Tenant, setTenant] = useState([initTenant]);
  const [area, setArea] = useState([initArea]);
  const [areaselect, setAreaselect] = useState([initArea]);
  const [year_select, setyear_select] = useState("")
  const [year_select_filter, setyear_select_filter] = useState("")

  const [FilterTenant, setFilterTenant] = useState([initTenant]);
  const [select_industrial , setSelectIndustrial] = useState("")
  const [select_tenant , setSelect_tenant] = useState("")
  const [delete_status, setdelete_status] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  // const [bodyAsset, setbodyAsset] = useState(initCreate);

  const factor_unit = ["GWP","kgN2O/kgNremoved","kgCH4/kgCODremoved","kgCH4/kgBODremoved","tCO2/L","tCO2/kWh","tCO2eq/kg"];
  const months = ["January", "February", "March","April", "May", "June", "July", "August", "September","October","November","December"];
  const GroupData = [
    {
      key : "", value: "Default"
    },
    {
      key : "area", value: "Per Area"
    },
    {
      key : "all", value: "All Data"
    }
  ]
  console.log(filter_group)
  // bodyAsset.threshold_month = []
  const [bodyAsset, setbodyAsset] = useObjectState({
    ...initCreate,
    threshold_month: [defaultMonth],
  });
  const yaers = [2020,2021,2022,2023];

  // Pagination
  const [currentData, setCurrentData] = useState([initThresholdSetting]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  useEffect(() => {
    

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // console.log(area, types)
    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));

    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAreaFilter(bodyAsset.tenant_id)
    .then(res => {
      // console.log(res.data)
      setAreaselect(res.data.data);
    }).catch(err => setAreaselect([]));
  }, [modal_xlarge, select_industrial, select_tenant, bodyAsset.tenant_id])

  useEffect(()=>{
    var params = {
      type:filter_type,
      search:search_name
    }
    EmissionFactorService.getAll(filter_type,search_name)
    .then(res => {
      setEmissionFactor(res.data.data);
    }).catch(err => console.log(err));

    ThresholdSettingService.getAll(year_select_filter,filter_area)
    .then(res => {
      // setTypes(res.data.data);
      setSettingThreshold(res.data.data)
      
    }).catch(err => {
      setSettingThreshold([])
      console.log(err)
    });
  },[ filter_type, search_name, modal_xlarge, delete_status, filter_area, year_select_filter])
  // Filter Tenant
  var tableData1 = Tenant;
  useEffect(() => {
    if (bodyAsset.industrial_id) {
      tableData1 = Tenant.filter((data)=>
        data.industrial_park_id==bodyAsset.industrial_id
      )
      setFilterTenant(tableData1)
    }
    else{
      setFilterTenant(tableData1)
    }
  }, [bodyAsset.industrial_id, tableData1])
  
  useEffect(() => {
    const endOffset = dataOffset + 2;
    setCurrentData(SettingThreshold.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(SettingThreshold.length / 2));
  }, [SettingThreshold, dataOffset]);
  useEffect(() => {
    // console.log('gg', bodyAsset)
    bodyAsset.threshold_month = []
    for (let index = 0; index < months.length; index++) {
      var parse_id = index+1 ;
      var add_month_id = "";
      if (parse_id<10) {
        add_month_id = "0" + parse_id.toString()
      }
      else {
        add_month_id = parse_id.toString()
      }
      bodyAsset.threshold_month.push(
        {
          month_id : add_month_id,
          month : months[index],
          lower_threshold : 0,
          upper_threshold : 0,
          unit : ""
        }
      )
    }
    // setbodyAsset({threshold_month:[defaultMonth]})
    // setbodyAsset({threshold_month:arr_month})
  }, [])
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(date, dateString);
    setyear_select(dateString.toString())
  };
  const onChangeDateFilter: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(date, dateString);
    setyear_select_filter(dateString.toString())
  };
  // console.log("year select",year_select);
  // console.log("year select 1",onchange)
  const editParam = (
    idx: number,
    data: Partial<{
      month: string;
      lower_threshold: number;
      upper_threshold: number;
      unit: string;
    }>
  ) => {
    const arr = [...bodyAsset.threshold_month];
    // console.log(" arraynya ",idx,data)

    arr[idx] = { ...arr[idx], ...data };
    // console.log(" cekekek",arr[idx])

    setbodyAsset({threshold_month:arr})
    // console.log(" cekekek",bodyAsset)

    // setBody({ parameter: arr });
  };
  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % SettingThreshold.length;
    setDataOffset(newOffset);
  }; 

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    // console.log(bodyAsset)
    for (let index = 0; index < bodyAsset.threshold_month.length; index++) {
      if (bodyAsset.threshold_month[index].lower_threshold!=0 && bodyAsset.threshold_month[index].upper_threshold && bodyAsset.threshold_month[index].unit!="") {
        var parse_date = year_select + "-" + bodyAsset.threshold_month[index].month_id + "-01";
        var data = {
          date_month :parse_date,
          lower_threshold : bodyAsset.threshold_month[index].lower_threshold,
          upper_threshold : bodyAsset.threshold_month[index].upper_threshold,
          unit : bodyAsset.threshold_month[index].unit,
          area_id : bodyAsset.area_id
        }
        ThresholdSettingService.create(data)
        .then(response => {
          console.log(response)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
      // const element = array[index];
      
    }
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your data saved!',
      showConfirmButton: false,
      timer: 1500
    })
    setbodyAsset(initCreate);
    setmodal_xlarge(false)
    
  }
  const handleDelete = (year:string, id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this Threshold!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        ThresholdSettingService.remove(year,id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your Threshold has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    // setdelete_status(false)
    
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Setting Threshold" link_title="/setting-threshold" breadcrumbItem="Setting Threshold" />
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light float-end"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add Entry
                </button>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={12}>
              <Card>
                <div className="table-responsive">
                  
                  <CardBody>
                  <Row>
                    <Col md={8}></Col>
                    <Col md={4}>
                      <Row>
                        <Col sm="6">
                          <div className="text-sm-end">
                            <div className="mb-0">
                              <Flex vertical gap="small">
                                <DatePicker 
                                  onChange={onChangeDateFilter}
                                  picker="year"
                                  size="large"
                                  />
                                </Flex>
                            </div>
                          </div>
                          
                        </Col>
                        <Col sm="6">
                          <div className="mb-0">
                            <select 
                              value={filter_area} 
                              onChange={({ currentTarget }) => setFilterarea(currentTarget.value,)}
                              id="area_id"
                              name="area_id"
                              className="form-select">
                                  <option value=""> Select Area</option>
                                  {
                                    areaselect?.map((raw, index)=>(
                                      <option 
                                        key={raw.id} 
                                        value={raw.id}
                                        >{raw.name}
                                      </option>

                                    ))
                                  }
                            </select>
                          </div>
                        </Col>
                        
                      </Row>
                    </Col>
                    {/* <Col xl={2}> */}
                      
                      <div>
                        <Modal
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              New Setting Threshold
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Modal Content */}
                          <div className="modal-body">
                          <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
                              <select 
                                value={bodyAsset.industrial_id} 
                                onChange={handleInputChange}
                                placeholder="Industrial Park"
                                id="industrial_id"
                                name="industrial_id"
                                className="form-select">
                                    <option value="">Select Industrial Park</option>
                                    {
                                      Industrial?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw.id}
                                          >{raw.legal_name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>
                              <select 
                                value={bodyAsset.tenant_id} 
                                onChange={handleInputChange}
                                placeholder="Tenant"
                                id="tenant_id"
                                name="tenant_id"
                                className="form-select">
                                    <option value="">Select Tenant</option>
                                    {
                                      FilterTenant?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw.id}
                                          >{raw.legal_name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Area</Label>

                              <select 
                                value={bodyAsset.area_id} 
                                onChange={handleInputChange}
                                id="area_id"
                                name="area_id"
                                className="form-select">
                                    <option value=""> Select Area</option>
                                    {
                                      areaselect?.map((raw, index)=>(
                                        <option 
                                          key={raw.id} 
                                          value={raw.id}
                                          >{raw.name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Year</Label>
                              <Flex vertical gap="small">
                              <DatePicker 
                                onChange={onChange}
                                picker="year" />
                              </Flex>
                              
                            </div>
                            <hr />
                            <div className="mb-3">
                              <h5>Target Per Month</h5>
                            </div>
                            <div className="mb-3">
                              <Row>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Month</Label>
                                </Col>
                                <Col>
                                <Label htmlFor="example-text-input" className="form-Label">Lower Threshold</Label>
                                </Col>
                                <Col>
                                <Label htmlFor="example-text-input" className="form-Label">Upper Threshold</Label>
                                </Col>
                                <Col>
                                <Label htmlFor="example-text-input" className="form-Label">Unit</Label>
                                </Col>
                              </Row>
                              {
                                bodyAsset.threshold_month.map((threshold_month,idx)=>(
                                  <Row key={idx} style={{marginTop:"10px"}}>
                                    <Col>
                                      <Input 
                                        className="form-control bg-light-subtle" 
                                        type="text" 
                                        // id="usage_unit"
                                        // name="usage_unit"
                                        // value={raw}
                                        disabled={true}
                                        onChange={({ currentTarget: e }) => editParam(idx, { month: e.value })}
                                        value={threshold_month.month}
                                      />
                                    </Col>
                                    <Col>
                                      <Input 
                                        className="form-control" 
                                        type="number" 
                                        id="lower_threshold"
                                        name="lower_threshold"
                                        step="any"
                                        onChange={({ currentTarget: e }) => editParam(idx, { lower_threshold: Number(e.value) })}
                                        value={threshold_month.lower_threshold}
                                      />
                                    </Col>
                                    <Col>
                                      <Input 
                                        className="form-control" 
                                        type="number" 
                                        id="upper_threshold"
                                        name="upper_threshold"
                                        onChange={({ currentTarget: e }) => editParam(idx, { upper_threshold: Number(e.value) })}
                                        value={threshold_month.upper_threshold}


                                      />
                                    </Col>
                                    <Col>
                                      <Input 
                                        className="form-control" 
                                        type="text" 
                                        id="unit"
                                        name="unit"
                                        onChange={({ currentTarget: e }) => editParam(idx, { unit: e.value })}
                                        value={threshold_month.unit}
                                      />
                                    </Col>
                                  </Row>
                                  
                                ))
                              }
                            </div>
                            
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Cancel
                              </button>
                              {
                                <button 
                                  type="button"
                                  onClick={() => {
                                    save();
                                  }}
                                  className="btn btn-success">
                                  Save
                                </button>
                                }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                    {/* </Col> */}
                  </Row>
                  <br />
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-0 table">
                        <thead className="bg-success text-white">
                          <tr >
                            <th rowSpan={2} style={{textAlign:"center", paddingBottom:"2%"}}>Area</th>
                            <th rowSpan={2} style={{textAlign:"center", paddingBottom:"2%"}}>Years</th>
                            <th colSpan={4} style={{textAlign:"center"}}>Target per Month</th>
                            <th rowSpan={2} style={{textAlign:"center", paddingBottom:"2%"}}>Action</th>
                          </tr>
                          <tr>
                            <th style={{textAlign:"center"}}>Month</th>
                            <th style={{textAlign:"center"}}>Lower Threshold	</th>
                            <th style={{textAlign:"center"}}>Upper Threshold	</th>
                            <th style={{textAlign:"center"}}>Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          { currentData.length!=0 ?
                            currentData.map((raw)=>
                              raw.monthly_threshold.map((res,idx)=>(
                                <tr key={idx}>
                                
                                  { idx==0 ? (
                                    <Fragment>
                                      <td rowSpan={raw.monthly_threshold.length}>
                                      {area.map((data,i)=>
                                        data.id==raw.area_id ? data.name : ""
                                      )}
                                      </td>
                                      <td rowSpan={raw.monthly_threshold.length}>{raw.year}</td>
                                      <td> {moment(res.date_month).format("MMMM") }</td>
                                      <td> {res.lower_threshold}</td>
                                      <td> {res.upper_threshold}</td>
                                      <td> {res.unit}</td>  
                                      <td rowSpan={raw.monthly_threshold.length}>
                                        <div className="gap-5" style={{textAlign:"center"}}>
                                          <Link className="btn btn-soft-success waves-effect waves-light" to={"/setting-threshold/edit/"+raw.year+"/"+raw.area_id}>
                                              <i
                                              className=" bx bx-pencil font-size-18 align-middle"
                                              id="edittooltip"
                                              ></i>
                                          </Link>
                                          &nbsp;
                                          <a className="btn btn-soft-danger waves-effect waves-light" href="#"
                                              onClick={() => {handleDelete(raw.year, raw.area_id)}} 
                                          >
                                              <i
                                              className=" bx bx-trash-alt font-size-18"
                                              id="edittooltip"
                                              ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </Fragment>
                                  )
                                  :
                                  (
                                    <Fragment>
                                      <td> {moment(res.date_month).format("MMMM") }</td>
                                      <td> {res.lower_threshold}</td>
                                      <td> {res.upper_threshold}</td>
                                      <td> {res.unit}</td>
                                    </Fragment>
                                  )
                                  }
                                </tr>
                              ))
                            )
                            :
                            <tr >
                              <td colSpan={7} style={{textAlign:"center"}}>Data Not Avaliable</td>
                            </tr>
                          }
                          
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                    <div className="text-sm-end">
                      <div className="justify-content-end pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmissionFactorPage;
