import http from "../default";
import authHeader from "./AuthHeaderService";
const user_token = localStorage.getItem('user');

const getAll = (start:string, end:string, tenant_id:string) => {
  return http.get(`/carbon/v1/emission-data?date_start=${start}&date_end=${end}&tenant_id=${tenant_id}`, { headers: authHeader() });
};
const getRawData = (start:string, end:string, tenant_id:string, flag:boolean) => {
  return http.get(`/carbon/v1/emission-data-raw?date_start=${start}&date_end=${end}&tenant_id=${tenant_id}&flag=${flag}`, { headers: authHeader() });
};
const getId = (id:any) => {
  return http.get(`/carbon/v1/uploaded-data/${id}`, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/emission-data/${id}`, { headers: authHeader() });
};

const getPreviewEmission = () => {
  return http.get("/carbon/v1/calculate-emission", { headers: authHeader() });
};
const create = (data:any, headers:any) => {
  return http.post("/carbon/v1/emission-data", data, headers);
};

const saveCalculationEmission = (data:any, ) => {
  // return http.post("/carbon/v1/save-emission-calculation", data, );
  return http.post("/carbon/v1/save-emission-calculation", data, { headers: authHeader() });
};

const getAssetUSage = (size:number, start:string, end:string) => {
  return http.get(`/carbon/v1/asset-usage?size=${size}&start-date=${start}&end-date=${end}`, { headers: authHeader() });
};

const DataCollectionService = {
  getAll,
  getId,
  create,
  getPreviewEmission,
  saveCalculationEmission,
  remove,
  getAssetUSage,
  getRawData,
};

export default DataCollectionService;
