import React, {useEffect, useMemo, useState} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  Input,
  Label,
 } from "reactstrap";

import ReactPaginate from "react-paginate"; 
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import EmissionFactorService from '../../../api/service/EmissionFactorService';
import AssetsListService from '../../../api/service/AssetsListService';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import { mapKeys } from "lodash";

const EmissionFactorPage = () => {
  document.title = "Emission Factor | Carbon Platform";
  const initEmissionFactor = {
    id :"",
    name : "",
    type : "",
    emission_factor_value :"",
    emission_factor_unit :"",
    reference:"",
    reference_link:"" 
  }
  const initCreate = {
    name:"",
    type:"",
    emission_factor_value:0,
    emission_factor_unit:"",
    reference:"",
    reference_link:""
  }
  const [EmissionFactor, setEmissionFactor] = useState([initEmissionFactor]);
  const [types, setTypes] = useState([]);
  const [filter_type , setFiltertype] = useState("")
  const [search_name , setSearchName] = useState("")


  const [delete_status, setdelete_status] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [bodyAsset, setbodyAsset] = useState(initCreate);

  const factor_unit = ["GWP","kgN2O/kgNremoved","kgCH4/kgCODremoved","kgCH4/kgBODremoved","tCO2/L","tCO2/kWh","tCO2eq/kg"];


  // Pagination
  const [currentData, setCurrentData] = useState([initEmissionFactor]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  useEffect(() => {
    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // console.log(area, types)
  }, [])

  useEffect(()=>{
    var params = {
      type:filter_type,
      search:search_name
    }
    EmissionFactorService.getAll(filter_type,search_name)
    .then(res => {
      setEmissionFactor(res.data.data);
    }).catch(err => console.log(err));

  },[ filter_type, search_name, modal_xlarge, delete_status])

  // Filter Data
  // var DataTable = EmissionFactor;
  // useEffect(()=>{ 
  //   // var filter = {name:search_name , type: filter_type};
  //   DataTable = EmissionFactor.filter((data)=>
  //     data.name == search_name || data.type ==filter_type
  //     )
  //     console.log(DataTable)

  // },[ filter_type, search_name])
  
  useEffect(() => {
    const endOffset = dataOffset + 10;
    setCurrentData(EmissionFactor.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(EmissionFactor.length / 10));
  }, [EmissionFactor, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % EmissionFactor.length;
    setDataOffset(newOffset);
  }; 

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    var data = {
      name : bodyAsset.name,
      type : bodyAsset.type,
      emission_factor_value: bodyAsset.emission_factor_value,
      emission_factor_unit: bodyAsset.emission_factor_unit,
      reference :bodyAsset.reference ,
      reference_link : bodyAsset.reference_link
    }
    console.log(data)
    EmissionFactorService.create(data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setbodyAsset(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this Asset!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        EmissionFactorService.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your Asset has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Emission Factor" link_title="/emission-factor" breadcrumbItem="Emission Factor" />
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light btn-md float-end"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add Entry
                </button>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={12}>
              <Card>

                <div className="table-responsive">
                  <CardBody>
                  <Row>
                      <Col>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col sm='7'>
                            <div className="mb-0">
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="name"
                                name="name"
                                placeholder="Search Name"
                                value={search_name}
                                onChange={({ currentTarget }) => setSearchName(currentTarget.value,)}
                                // onChange={handleInputChange}
                                />
                            </div>
                          </Col>
                          <Col sm="5">
                            <div className="mb-0">
                              <select 
                                value={filter_type} 
                                // onChange={Handlefilter}
                                onChange={({ currentTarget }) => setFiltertype(currentTarget.value,)}
                                className="form-select">
                                  <option value="">Type</option>
                                    {
                                      types?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw}
                                          // onChange={Handlefilter}
                                          >{raw}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col xl={2}> */}
                        {/* <Button className="float-end btn btn-success btn-sm">Tambah</Button> */}
                        
                        <div>
                        <Modal
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              New Emission Factor
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Modal Content */}
                          <div className="modal-body">
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Name</Label>
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="name"
                                name="name"
                                value={bodyAsset.name}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Type</Label>
                              <select 
                                value={bodyAsset.type} 
                                onChange={handleInputChange}
                                id="type"
                                name="type"
                                className="form-select">
                                    <option value=""></option>

                                    {
                                      types?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw}
                                          // onChange={Handlefilter}
                                          >{raw}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Emission Factor Value</Label>
                              <Input 
                                className="form-control" 
                                type="number" 
                                id="emission_factor_value"
                                name="emission_factor_value"
                                value={bodyAsset.emission_factor_value}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Emission Factor Unit</Label>
                              <select 
                                value={bodyAsset.emission_factor_unit} 
                                onChange={handleInputChange}
                                id="emission_factor_unit"
                                name="emission_factor_unit"
                                className="form-select">
                                    <option value=""></option>

                                    {
                                      factor_unit?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw}
                                          // onChange={Handlefilter}
                                          >{raw}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Row>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">Referances</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="reference"
                                    name="reference"
                                    value={bodyAsset.reference}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                <Col>
                                  <Label htmlFor="example-text-input" className="form-Label">References Link</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="reference_link"
                                    name="reference_link"
                                    value={bodyAsset.reference_link}
                                    onChange={handleInputChange}
                                    />
                                </Col>
                                
                              </Row>
                            </div>
                            

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Cancel
                              </button>
                              {
                                <button 
                                  type="button"
                                  onClick={() => {
                                    save();
                                  }}
                                  className="btn btn-success">
                                  Save Asset
                                </button>
                                }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                      {/* </Col> */}
                    </Row>
                    <br />
                    <div className="table-responsive">
                      <Table className="table-bordered table mb-0">
                        <thead className="bg-success text-white ">
                          <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Emission Factor Value </th>
                            <th>Emission Factor Unit</th>
                            <th>Reference</th>
                            <th>Reference Link</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            currentData.length >0 ? (
                              currentData.map((raw,index)=>(
                                <tr key={raw.id}>
                                  <td>{raw.name}</td>
                                  <td>{raw.type}</td>
                                  <td>{raw.emission_factor_value}</td>
                                  <td>{raw.emission_factor_unit}</td>
                                  <td> {raw.reference}</td>
                                  <td><a className="text-primary " href={raw.reference_link} target="_blank" rel="noopener noreferrer">
                                        Link
                                      </a></td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <Link className="text-success" to={"/emission-factor/edit/"+raw.id}>
                                        <i
                                          className=" bx bx-pencil font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </Link>
                                      <a className="text-danger" href="#"
                                        onClick={() => {handleDelete(raw.id)}} 
                                      >
                                        <i
                                          className=" bx bx-trash-alt font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ):
                            (
                              <tr>
                                <td colSpan={7}>No Data Emission Factor</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                    <div className="text-sm-end">
                      <div className="justify-content-end pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmissionFactorPage;
