//images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";

interface ChatUserProps {
  id : number;
  img ?: any;
  name : string;
  designation : string;
  color ?: string;
  email : string;
  projects : string;
  tags : Array<string>
}

const users : Array<ChatUserProps> = [
  {
    id: 1,
    name: "Paksi Yuda",
    designation: "SUSTAINABILITY DIV",
    color: "primary",
    email: "david@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant A", "Tenant B"],
  },
  {
    id: 2,
    img: avatar2,
    name: "Permana",
    designation: "Manager",
    email: "frank@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant A", "Tenant B", "Tenant C"],
  },
  {
    id: 3,
    img: avatar3,
    name: "Rafael Morales",
    designation: "Member",
    email: "Rafael@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant A"],
  },
  {
    id: 4,
    name: "Mark Ellison",
    designation: "Operator",
    color: "success",
    email: "mark@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant B"],
  },
  {
    id: 5,
    img: avatar4,
    name: "Minnie Sugiono",
    designation: "Manager",
    email: "minnie@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant C"],
  },
  {
    id: 6,
    img: avatar5,
    name: "Shirley Smith",
    designation: "Head Div",
    email: "shirley@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant D"],
  },
  {
    id: 7,
    name: "John Jhono",
    designation: "Admin",
    color: "info",
    email: "john@musagreen.com",
    projects: "PT. Industrial Park xxx",
    tags: ["Tenant X"],
  },
  
]
const userProfile : Object = {
  id: 1,
  name: "Cynthia Price",
  designation: "UI/UX Designer",
  img: "avatar1",
  projectCount: 125,
  revenue: 1245,
  personalDetail:
    "Hi I'm Cynthia Price,has been the industry's standard dummy text To an English person, it will seem like simplified English, as a skeptical Cambridge.",
  phone: "(123) 123 1234",
  email: "cynthiamusagreen@gmail.com",
  location: "California, United States",
  experiences: [
    {
      id: 1,
      iconClass: "bx-server",
      link: "#",
      designation: "Back end Developer",
      timeDuration: "2016 - 19",
    },
    {
      id: 2,
      iconClass: "bx-code",
      link: "#",
      designation: "Front end Developer",
      timeDuration: "2013 - 16",
    },
    {
      id: 3,
      iconClass: "bx-edit",
      link: "#",
      designation: "UI /UX Designer",
      timeDuration: "2011 - 13",
    },
  ],
  projects: [
    {
      id: 1,
      name: "musagreen admin UI",
      startDate: "2 Sep, 2019",
      deadline: "20 Oct, 2019",
      budget: "$506",
    },
    {
      id: 2,
      name: "musagreen admin Logo",
      startDate: "1 Sep, 2019",
      deadline: "2 Sep, 2019",
      budget: "$94",
    },
    {
      id: 3,
      name: "Redesign - Landing page",
      startDate: "21 Sep, 2019",
      deadline: "29 Sep, 2019",
      budget: "$156",
    },
    {
      id: 4,
      name: "App Landing UI",
      startDate: "29 Sep, 2019",
      deadline: "04 Oct, 2019",
      budget: "$122",
    },
    {
      id: 5,
      name: "Blog Template",
      startDate: "05 Oct, 2019",
      deadline: "16 Oct, 2019",
      budget: "$164",
    },
    {
      id: 6,
      name: "Redesign - Multipurpose Landing",
      startDate: "17 Oct, 2019",
      deadline: "05 Nov, 2019",
      budget: "$192",
    },
    {
      id: 7,
      name: "Logo Branding",
      startDate: "04 Nov, 2019",
      deadline: "05 Nov, 2019",
      budget: "$94",
    },
  ],
}
export { users, userProfile }