import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// Service
import DataCollectionService from '../../../api/service/DataCollectionService';


//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const initialCollectDataState = {
    id: "",
    created_at:"",
    date_start: "",
    date_end: "",
    file_name:"",
    uploaded_by: "",
    file_link: "",
    tenant_name: "",
    tenant_id: "",
    calculated:false
  };
  const [CollectData, setCollectData] = useState([initialCollectDataState]);

  useEffect(() => {
    DataCollectionService.getAll("","","")
    .then(response => {
      setCollectData(response.data.data.emission_datas);
    })
    .catch(e => {
      console.log(e);
    });

  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-lg" />
          <span className="badge bg-danger rounded-pill">{CollectData.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="#" className="small">
                  {" "}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {
              CollectData.map((raw,index)=>(
                // index!=4 ?
                <Link to="" className="text-reset notification-item" key={raw.id}>
                  <div className="d-flex">
                    <div className="avatar-sm me-3">
                      <span className="avatar-title bg-success rounded-circle font-size-16">
                        <i className="bx bx-error-alt" />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">
                        {/* {props.t("Nov 2022 data missing")} */}
                        Success Upload - &nbsp;
                        {raw.file_name.length > 10 ?
                          `${raw.file_name.substring(0, 10)}...` : raw.file_name
                        }
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {props.t("Success")}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {props.t("12-06-2022 20:00:00")}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
                // :
                // ""
              ))
            }
           
            
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
