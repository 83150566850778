export default function authHeader() {
  const user_token = localStorage.getItem('user');
  // const user = JSON.parse(localStorage.getItem('user'));

  if (user_token) {
    // for Node.js Express back-end
    return { Authorization: 'Bearer ' + user_token };
  } else {
    return {};
  }
}