import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  Card, 
  CardBody, 
  Col, 
  Container, 
  Row,
  Modal,
  Label, 
  // Table, 
  Collapse,
  Breadcrumb,
  BreadcrumbItem 

} from "reactstrap";
import { isEmpty, keys, map } from "lodash";
import {useParams} from 'react-router-dom';
import moment from "moment"
import { Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import Swal from 'sweetalert2';
import { DateRangePicker, Stack } from 'rsuite';


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Image
import logo from "../../../assets/images/logo-sm.svg";

import { getInvoiceDetail as onGetInvoiceDetail } from "../../../store/invoices/actions";

// API
import DataCollectionService from '../../../api/service/DataCollectionService';
import CostService from '../../../api/service/CostService';
import TenantService from '../../../api/service/TenantService';
import AssetsListService from '../../../api/service/AssetsListService';
import AssetUsageService from '../../../api/service/AssetUsageService';
import "../index.css";
// import collectionlist from "../../common/data/collection"

//redux
import { useSelector, useDispatch } from "react-redux";
import Item from "antd/es/list/Item";

interface Item {
  key:string,
  id: string,
  usage_value: number,
  usage_unit: string,
  measured_at: string,
  month: string,
  note: string,
  asset_component_id: string,
  component_name: string,
  component_type: string,
  area_name: string,
  area_type: string,
  operational_cost_id : string,
  operational_cost_value:number,
  operational_cost_unit:string,
  tenant_id:string
}
interface ItemValidate {
  usage_value: number,
  operational_cost_value:number,
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              // required: dataIndex=='operational_cost_currency' || dataIndex=='note' ? false : true,
              required:  false ,
              message: `Please Input ${title}!`,
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const CollectionDetails = (props: any) => {
  // const {data} = collectionlist();

  document.title = " Data Collection | Carbon Platform";
  
  const initAssetUsage = {
    id: "",
    usage_value: 0,
    usage_unit: "",
    measured_at: "",
    time_unit: "",
    note: "",
    asset_component_id: "",
    emission_data_id: ""
  }
  const  initAssetList = {
    id: "",
    name: "",
    type: "",
    quantity: 0,
    installed_at: "",
    supplier: {
        id: "",
        name: "",
        legal_name: "",
        origin_country: ""
    },
    area: {
      id: "",
      name: "",
      type: "",
      tenant_id:""
    },
    emission_factor: {
      id: "",
      name: "",
      type: "",
    },
    status: false

  }
  const initOperationalCost = {
    id: "",
    cost_value: 0,
    cost_currency: "",
    asset_usage_id: ""
  }
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initCreate = {
    name:"",
    type:"",
    quantity:0,
    installed_at:"",
    lifetime_day:0,
    supplier_id:"",
    area_id:"",
    area_type:"",
    date :"",
    usage_value :0,
    usage_unit :"",
    cost_value:0,
    cost_unit:"",
    note:""
  }
  const initialTenantState = {
    id: "",
    name: "",
    legal_name: "",
    head_count:"",
    location_latitude: Number,
    location_longitude: Number,
    location_country: "",
    location_area_ha: "",
    business_sector:"",
    industrial_park_id:""
  };

  const { invoiceDetail } = useSelector((state: any) => ({
    invoiceDetail: state.Invoices.invoiceDetail,
  }));
  const [col5, setcol5] = useState(true);

  const t_col5 = () => {
    setcol5(!col5);
  };
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);
  const [Asset, setAssetUsage] = useState([initAssetUsage]);
  const [OperationalCost, setOperationalCost] = useState([initOperationalCost]);
  const [AssetComponentList, setAssetComponentList] = useState([initAssetList]);
  const [AssetComponent, setAssetComponent] = useState([initAssetList]);
  const [Tenants, setTenants] = useState([initialTenantState]);
  const [filter , setFilter] = useState("test");
  
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [status_del, setstatusdel] = useState('');
  const [status_edit, setstatusedit] = useState('');
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [status_save, setstatus_save] = useState(false);
  const [status_submit, setstatus_submit] = useState(false);
  const [loading, setLoading] = useState(false);

  var start = "";
  var end = "";
  if(FilterDate!=null){
    start = moment(FilterDate[0]).format("YYYY-MM-DD");
    end = moment(FilterDate[1]).format("YYYY-MM-DD")
    if (start==end) {
      start=""
      end = ""
    }
  }
  else {
    start=""
    end = ""
  }
  const isEditing = (record: Item) => record.key === editingKey;
  var DataRawFilter: Item[] = [];
  const [dataSource, setDataSource] = useState<Item[]>();
  useEffect(()=>{
    TenantService.getAll().then(res => {
      setTenants(res.data.data);
    }).catch(err => console.log(err));
    setLoading(true);
    DataCollectionService.getRawData(start,end,filter,true)
    .then(response => {
      setTimeout(function(){
          setLoading(false);
          setDataSource(response.data.data.data);
      },300)
      // setLoading(false);
      // setDataSource(response.data.data.data);
    })
    .catch(error => {
      setLoading(false);
      setDataSource([]);
     
    });
    setstatusedit("")
    setstatus_submit(false);
    // console.log("keenggonl")
  },[filter, FilterDate, status_del, status_edit, status_submit ])
  // useEffect(()=>{
    
  //   if (filter=="" || filter=='test') {
  //     DataRawFilter = originDataCD
  //     setDataSource(DataRawFilter)
  //   }else{
  //     DataRawFilter = originDataCD.filter((raw)=>
  //       raw.tenant_id==filter
  //     )
  //     setDataSource(DataRawFilter)
  //   }
    
  //   // console.log(DataRawFilter)
  // },[filter, originDataCD ])
  // Validation button save
  useEffect(()=>{
    if (bodyAsset.name!='' && bodyAsset.date!='' && bodyAsset.usage_value!=null && bodyAsset.usage_unit !='') {
      setstatus_save(true)
    }else{
      setstatus_save(false)
    }

  },[bodyAsset.name, bodyAsset.date, bodyAsset.usage_value, bodyAsset.usage_unit])
  useEffect(()=>{
    AssetsListService.getAll(bodyAsset.area_id, bodyAsset.area_type, "")
    .then(res => {
      try {
        setAssetComponentList(res.data.data.asset_component_list)
        // setdataAssetList(res.data.data.asset_component_list);
        
      } catch (error) {
        // setdataAssetList([]);
        setAssetComponentList([])
      }
      // console.log(dataAssetList)
      
    }).catch(err => console.log(err));
  },[ bodyAsset.area_id, bodyAsset.area_type, modal_xlarge,])
  
  useEffect(() => {
    // Form Add 
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // Show Data
    TenantService.getAll().then(res => {
      setTenants(res.data.data);
    }).catch(err => console.log(err));
  }, [status_del, status_edit, status_submit])
  
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.key);
  };
  const Handlefilter = (e:any) => {
    setFilter(e.target.value);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const add_data = ()=> {
    // console.log(bodyAsset.date)
    var data_asset = {
      usage_value: bodyAsset.usage_value,
      usage_unit: bodyAsset.cost_unit,
      measured_at: moment(bodyAsset.date).format("YYYY-MM-DD") ,
      time_unit: "",
      note: bodyAsset.note,
      asset_component_id: bodyAsset.name
    }
    
    // console.log(data)
    AssetUsageService.create(data_asset)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      var data_cost = {
        cost_value:bodyAsset.cost_value,
        cost_currency:bodyAsset.cost_unit,
        asset_usage_id:response.data.data.id
      }
      CostService.create(data_cost)
      .then(res=>{
        console.log("add data cost success")
      })
      .catch(e => {
        console.log(e);
      });

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setstatus_submit(true);
      setbodyAsset(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (key:string) => {
    // const newData = dataSource.filter((item) => item.key !== key);
    // setDataSource(newData);
    // console.log(key)
    // var data_delete = dataSource?.filter((data)=>
    //   data.key==key
    // )
    // setstatusdel(false);
    // console.log(status_del)

    var jumlah = 1+1;
    dataSource?.filter((data:any)=>{
      data.key==key ? 
      // console.log(data.operational_cost_id)
        AssetUsageService.remove(data.key)
        .then(res=>{
          console.log("Delete Asset Usage Success!")
          data.operational_cost_id!=="" ? 
            CostService.remove(data.operational_cost_id)
            .then(res=>{
              console.log("Delete Operational Cost Success!")
            })
            .catch(e => {
              console.log(e);
            })
          
          :""
        })
        .catch(e => {
          console.log(e);
        })
        
      :
      ""
    });
    setstatusdel(key);
    console.log(status_del)
    // console.log(data_delete[0]?.key)
    // AssetUsageService.remove(data_delete.key)
    // .then(res=>{
    //   console.log("Delete Asset Usage Success!")
    // })
    // .catch(e => {
    //   console.log(e);
    // });

    // if (data_delete[0].operational_cost_id!=="") {
    //   CostService.remove(data_delete[0].operational_cost_id)
    //   .then(res=>{
    //     console.log("Delete Operational Cost Success!")
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
    // }
  };

  const save = async (key: string) => {
    try {
      const row = (await form.validateFields()) as Item;
      setEditingKey('');

      var getdataEdit = dataSource?.filter((data)=> data.key==key);
      // console.log("get",getdataEdit, getData?.[0].area_name)
      // console.log("data edit : ",row, key)

      var data_asset = {
        usage_value: row.usage_value,
        usage_unit: getdataEdit?.[0].usage_unit,
        measured_at: moment(getdataEdit?.[0].month).format("YYYY-MM-DD") ,
        time_unit: getdataEdit?.[0].month,
        note: row.note,
        asset_component_id: getdataEdit?.[0].asset_component_id
      }
      AssetUsageService.update(key,data_asset)
      .then(res=>{
        console.log("Update Asset Usage Success!")
      })
      .catch(e => {
        console.log(e);
      });

      var data_cost = {
        cost_value:row.operational_cost_value,
        cost_currency:row.operational_cost_unit,
        asset_usage_id:key
      }
      CostService.update(getdataEdit?.[0].operational_cost_id,data_cost)
      .then(res=>{
        console.log("Update Cost Operational Success!")
      })
      .catch(e => {
        console.log(e);
      });
      console.log("data edit : ",row, key)
      setstatusedit(key);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Month',
      dataIndex: 'month',
    },
    {
      title: 'Area Type	',
      dataIndex: 'area_type',
    },
    {
      title: 'Area Name	',
      dataIndex: 'area_name',
    },
    {
      title: 'Component Name',
      dataIndex: 'component_name',
    },
    {
      title: 'Component Type',
      dataIndex: 'component_type',
    },
    {
      title: 'Usage Value',
      dataIndex: 'usage_value',
      editable: true,
    },
    {
      title: 'Usage Unit',
      dataIndex: 'usage_unit',

    },
    {
      title: 'Operational Cost Value',
      dataIndex: 'operational_cost_value',
      editable: true,

    },
    {
      title: 'Operational Cost Unit	',
      dataIndex: 'operational_cost_unit',
      editable: true,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      editable: true,

    },
    {
      title: 'Action',
      dataIndex: 'Action',
      width: '10%',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <div>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <i
                className=" bx bx-pencil font-size-18"
                id="edittooltip"
              ></i>
            </Typography.Link>

            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <a className="text-danger" style={{paddingLeft:"10px"}}>
                <i
                  className=" bx bx-trash-alt font-size-18"
                  id="edittooltip"
                ></i>
              </a>
            </Popconfirm>
          </div>
          
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'usage_value' || col.dataIndex === 'operational_cost_value' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  
  // console.log(CollectDataDetail);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Data Collection" breadcrumbItem=" Detail Collection" /> */}
          <Row>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Data Collection</h4>
              <div className="page-title-right">
                <Breadcrumb className="m-0" >
                  <BreadcrumbItem>
                    <Link to={'/form-input'}>
                    Data Collection
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    Data Form Input
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add Entry
                </button>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row> 
                    <Col sm="4">
                    </Col>
                    <Col sm="8">
                      <Row>
                        <Col sm="4"></Col>
                        <Col sm="4">
                        <div className="text-sm-end">
                          <div className="mb-0">
                            <select 
                              value={filter} 
                              onChange={Handlefilter}
                              className="form-select">
                                <option value="">All Tenant</option>
                                  {
                                    Tenants?.map((tenant,row)=>(
                                      <option 
                                        key={tenant.id} 
                                        value={tenant.id}
                                        // onChange={Handlefilter}
                                        >{tenant.name}
                                      </option>

                                    ))
                                  }
                              </select>
                          </div>
                        </div>
                        </Col>
                        <Col md="4">
                          <div className="text-sm-end">
                            <DateRangePicker
                                onChange={(e:any) => setFilterDate(e)}
                                placement="bottomEnd"
                                placeholder="Periode"
                                style={{ width: 340 }}
                              />
                        </div>
                        </Col>
                      </Row>
                      
                      
                      <div>
                        <Modal
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              Add Data Collection
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Modal Content */}
                          <div className="modal-body">
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Date</Label>
                                  <Input 
                                    className="form-control" 
                                    type="date" 
                                    id="date"
                                    name="date"
                                    value={bodyAsset.date}
                                    onChange={handleInputChange}
                                    />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Area Name</Label>

                                  <select 
                                    value={bodyAsset.area_id} 
                                    onChange={handleInputChange}
                                    id="area_id"
                                    name="area_id"
                                    className="form-select">
                                        <option value="">Area Name</option>
                                        {
                                          area?.map((raw, index)=>(
                                            <option 
                                              key={raw.id} 
                                              value={raw.id}
                                              >{raw.name}
                                            </option>

                                          ))
                                        }
                                  </select>
                                </div>
                               </Col>
                            </Row>
                            <Row>
                               
                               <Col>
                                <div className="mb-3">
                                    <Label htmlFor="example-text-input" className="form-Label">Component Type</Label>

                                    <select 
                                      value={bodyAsset.area_type} 
                                      onChange={handleInputChange}
                                      id="area_type"
                                      name="area_type"
                                      className="form-select">
                                          <option value="">Type</option>

                                          {
                                            types?.map((raw, index)=>(
                                              <option 
                                                key={index} 
                                                value={raw}
                                                // onChange={Handlefilter}
                                                >{raw}
                                              </option>
                                            ))
                                          }
                                    </select>
                                  </div>
                               </Col>
                               <Col>
                                <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Component Name</Label>

                                  <select 
                                    value={bodyAsset.name} 
                                    onChange={handleInputChange}
                                    id="name"
                                    name="name"
                                    className="form-select">
                                        <option value="">Choose Component Name</option>
                                        {
                                          AssetComponentList?.map((raw, index)=>(
                                            <option 
                                              key={raw.id} 
                                              value={raw.id}
                                              >{raw.name}
                                            </option>

                                          ))
                                        }
                                  </select>
                                  {
                                    AssetComponentList.length==0 ? <p className="text-danger">Asset Not Found</p> : ""
                                  }
                                </div>
                               </Col>
                            </Row>
                            <Row>
                               <Col>
                                 <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label"> Usage Value</Label>
                                  <Input 
                                    className="form-control" 
                                    type="number" 
                                    id="usage_value"
                                    name="usage_value"
                                    value={bodyAsset.usage_value}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                
                               </Col>
                               <Col>
                                 <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Usage Unit</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="usage_unit"
                                    name="usage_unit"
                                    value={bodyAsset.usage_unit}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                
                               </Col>
                            </Row>
                            <Row>
                               <Col>
                                 <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Operational Cost Value</Label>
                                  <Input 
                                    className="form-control" 
                                    type="number" 
                                    id="cost_value"
                                    name="cost_value"
                                    value={bodyAsset.cost_value}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                
                               </Col>
                               <Col>
                                 <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Operational Cost Unit</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="cost_unit"
                                    name="cost_unit"
                                    value={bodyAsset.cost_unit}
                                    onChange={handleInputChange}
                                    />
                                </div>
                                
                               </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="example-text-input" className="form-Label">Note</Label>
                                  <Input 
                                    className="form-control" 
                                    type="text" 
                                    id="note"
                                    name="note"
                                    value={bodyAsset.note}
                                    onChange={handleInputChange}
                                    />
                                </div>
                              </Col>
                            </Row>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Cancel
                              </button>
                              {
                                <button 
                                  type="button"
                                  disabled={status_save ? false : true}
                                  onClick={() => {
                                    add_data();
                                  }}
                                  className="btn btn-success">
                                  Save
                                </button>
                                }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                      
                    </Col>
                  </Row> 
                  <br />
                  <Row>
                    
                    <Col sm="12">
                      <Form form={form} component={false}>
                        <Table
                          components={{
                            body: {
                              cell: EditableCell,
                            },
                          }}
                          bordered
                          dataSource={dataSource}
                          columns={mergedColumns}
                          rowClassName="editable-row"
                          pagination={{
                            onChange: cancel,
                          }}
                        />
                      </Form>
                    </Col>
                  </Row>
                  
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollectionDetails;
