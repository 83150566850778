import React, { useEffect, useMemo, useState } from "react";
import { Link ,useNavigate } from "react-router-dom";
import Icon from "@ailibs/feather-react-ts";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
import { Button, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import Swal from 'sweetalert2'; 
// Date range
import { DateRangePicker, Stack } from 'rsuite';
import DataCollectionService from '../../../api/service/DataCollectionService';
import TenantService from '../../../api/service/TenantService';
import "../index.css";
// Export Data
import { rawToExcel } from "../toexcel";

import moment from "moment";


interface ItemDataRaw {
  key:string,
  id: string,
  usage_value: number,
  usage_unit: string,
  month: string,
  note: string,
  asset_component_id: string,
  component_name: string,
  component_type: string,
  area_name: string,
  area_type: string,
  operational_cost_id : string,
  operational_cost_value:number,
  operational_cost_currency:string,
  tenant_id:string
}
const columns: TableColumnsType<ItemDataRaw> = [
  {
    title: 'Month',
    dataIndex: 'month',
  },
  {
    title: 'Area Type	',
    dataIndex: 'area_type',
  },
  {
    title: 'Area Name	',
    dataIndex: 'area_name',
  },
  {
    title: 'Component Name',
    dataIndex: 'component_name',
  },
  {
    title: 'Component Type',
    dataIndex: 'component_type',
  },
  {
    title: 'Usage Value',
    dataIndex: 'usage_value',
  },
  {
    title: 'Usage Unit',
    dataIndex: 'usage_unit',
  },
  {
    title: 'Operational Cost Value',
    dataIndex: 'operational_cost_value',
  },
  {
    title: 'Operational Cost Unit',
    dataIndex: 'operational_cost_currency',
  },
  {
    title: 'Note',
    dataIndex: 'note',
  },
];

const DataCollectionRow = () => {
  document.title = "Data Collection - Raw Data | Carbon Platform";
  const initialTenantState = {
    id: "",
    name: "",
    legal_name: "",
    head_count:"",
    location_latitude: Number,
    location_longitude: Number,
    location_country: "",
    location_area_ha: "",
    business_sector:"",
    industrial_park_id:""
  };
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [dataSource, setDataSource] = useState<ItemDataRaw[]>()
  const [loading, setLoading] = useState(false);

  const [Tenants, setTenants] = useState([initialTenantState]);
  const [filter , setFilter] = useState("");

  const [delete_status, setdelete_status] = useState(false);
  
  useEffect(()=>{
    var start = "";
    var end = "";
    if(FilterDate!=null){
      start = moment(FilterDate[0]).format("YYYY-MM-DD");
      end = moment(FilterDate[1]).format("YYYY-MM-DD")
      if (start==end) {
        start=""
        end = ""
      }
    }
    else {
      start=""
      end = ""
    }
  console.log(FilterDate)

    setLoading(true);
    DataCollectionService.getRawData(start,end,filter,false)
    .then(response => {
      setTimeout(function(){
          setLoading(false);
          setDataSource(response.data.data.data);
      },300)
      // setLoading(false);
      // setDataSource(response.data.data.data);
    })
    .catch(error => {
      setLoading(false);
      setDataSource([]);
     
    });
  },[filter, FilterDate ])
  
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const Handlefilter = (e:any) => {
    setFilter(e.target.value);
  };

  // Get Data 
  useEffect(() => {
    
    TenantService.getAll().then(res => {
      setTenants(res.data.data);
    }).catch(err => console.log(err));
    
  }, [delete_status, FilterDate])
  return (
    
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          
          <Row>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Data Collection</h4>
              <div className="page-title-right">
                <Breadcrumb className="m-0" >
                  <BreadcrumbItem>
                    <Link to={'/data-row'}>
                    Data Collection
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    Raw Data
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="12"> 
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <Link className="btn btn-light waves-effect waves-light" to="/data-collection">
                        <Icon size={15} name="file-plus" /> File Upload
                      </Link>
                      &nbsp;

                      <Link className="btn btn-light waves-effect waves-light" to="/form-input">
                        <Icon size={15} name="edit-3" /> Form Input
                      </Link>
                      &nbsp;
                      <button
                        type="button"
                          className="btn btn-light waves-effect waves-light"
                          onClick={() => {
                            rawToExcel("RawData",dataSource);
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-xl"
                        >
                          <i className="bx bx-export bx-xs me-1" ></i> Export
                        </button>
                      
                    </Col>
                    <Col sm="4">
                      <Row>
                        <Col sm="6">
                          <div className="text-sm-end">
                            <select 
                              value={filter} 
                              onChange={Handlefilter}
                              className="form-select">
                                <option value="">All Tenant</option>
                                  {
                                    Tenants?.map((tenant,row)=>(
                                      <option 
                                        key={tenant.id} 
                                        value={tenant.id}
                                        // onChange={Handlefilter}
                                        >{tenant.name}
                                      </option>

                                    ))
                                  }
                              </select>
                          </div>
                        </Col>
                        <Col sm="6">
                        <div className="text-sm-end">
                          <DateRangePicker
                              onChange={(e:any) => setFilterDate(e)}
                              placement="bottomEnd"
                              placeholder="Periode"
                              style={{ width: 300 }}
                            />
                        
                        </div>
                        </Col>
                      </Row>
                      
                    </Col>
                    
                  </Row>
                  <br />
                  <Table 
                    columns={columns}
                    bordered
                    // rowKey={(record)=>record.id} 
                    dataSource={dataSource} 
                    loading={loading}
                  />
                  
                </CardBody>
                
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DataCollectionRow;
