import Excel from "exceljs";
import moment from "moment";

export const rawToExcel = (name:string, sourceData:any)=> {
  const book = new Excel.Workbook();
  const seed = book.addWorksheet("Raw Data");
  seed.getCell(1, 1).value = "Month";
  seed.getCell(1, 2).value = "Area Type";
  seed.getCell(1, 3).value = "Area Name";
  seed.getCell(1, 4).value = "Component Name";
  seed.getCell(1, 5).value = "Component Type";
  seed.getCell(1, 6).value = "Usage Value";
  seed.getCell(1, 7).value = "Usage Unit";
  seed.getCell(1, 8).value = "Operational Cost Value";
  seed.getCell(1, 9).value = "Operational Cost Unit";
  seed.getCell(1, 10).value = "Note";
  sourceData?.forEach((row:any,idx:any) => {
    seed.getCell(2 + idx, 1).value = moment(row.measured_at).format("YYYY-MM-DD");
    seed.getCell(2 + idx, 2).value = row.area_type;
    seed.getCell(2 + idx, 3).value = row.area_name;
    seed.getCell(2 + idx, 4).value = row.component_name;
    seed.getCell(2 + idx, 5).value = row.component_type;
    seed.getCell(2 + idx, 6).value = row.usage_value;
    seed.getCell(2 + idx, 7).value = row.usage_unit;
    seed.getCell(2 + idx, 8).value = row.operational_cost_value;
    seed.getCell(2 + idx, 9).value = row.operational_cost_currency;
    seed.getCell(2 + idx, 10).value = row.note;


  });
  Download(book, name);
};


const Download = (book: Excel.Workbook, name: string) =>
  book.xlsx
    .writeBuffer({})
    .then(function (xls64) {
      // build anchor tag and attach file (works in chrome)
      var a = document.createElement("a");
      var data = new Blob([xls64], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      var url = URL.createObjectURL(data);
      a.href = url;
      a.download = name + ".xlsx";
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    })
    .catch(function (error) {
      console.log(error.message);
    });
