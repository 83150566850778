import http from "../default";
import authHeader from "./AuthHeaderService";

const key = "x-auth-token";
const getUser = (id:any) => {
  return http.get(`/carbon/v1/emission-factor/${id}`);
};

const AuthMe = () => {
  return http.get("/carbon/v1/auth/me", { headers: authHeader() });
};

const login = (data:any) => {
  return http.post("/carbon/v1/auth/token", data);
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("industrial_id");
};

const AuthService = {
  login,
  AuthMe,
  getUser,
  logout
};

export default AuthService;
