import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";
import {useParams} from 'react-router-dom';


import AssetsListService from '../../api/service/AssetsListService';
import EmissionFactorService from '../../api/service/EmissionFactorService';


const EditAsset = () => {
  document.title = "Assets List | Carbon Platform";
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initCreate = {
    name:"",
    type:"",
    quantity:0,
    installed_at:"",
    lifetime_day:0,
    supplier_id:"",
    area_id:"",
    emission_factor_mapping_id:"",
    emission_factor_id:""
  }
  const initEmissionFactor = {
    id:"",
    name:"",
    type:"",
    emission_factor_value:0,
    emission_factor_unit:"",
    reference:"",
    reference_link:""
  }
  
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const {id} = useParams();
  const navigate = useNavigate();
  const [EmissionFactor, setEmissionFactor] = useState([initEmissionFactor]);
  const [filter , setFilter] = useState("");

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const Handlefilter = (e:any) => {
    setFilter(e.target.value);
  };
  console.log(filter)
  const save = ()=> {
    var data = {
      name : bodyAsset.name,
      type : bodyAsset.type,
      quantity: bodyAsset.quantity,
      installed_at: bodyAsset.installed_at ,
      lifetime_day: bodyAsset.lifetime_day,
      supplier_id: null,
      area_id: bodyAsset.area_id
    }
    // console.log(bodyAsset)
    // Add Emission
    const CreateEmissionMapping = {
      asset_component_id:id,
      emission_factor_id:bodyAsset.emission_factor_id,
      scaling_ratio :1
    }
    if (bodyAsset.emission_factor_mapping_id==null) {
      EmissionFactorService.createEmissionMapping(CreateEmissionMapping)
      .then(response => {
        console.log("Success ");
      })
      .catch(e => {
        console.log(e);
      });
    }else{
      EmissionFactorService.updateEmissionMapping(bodyAsset.emission_factor_mapping_id,CreateEmissionMapping)
      .then(response => {
        console.log("update Emission Mapping Success ");
      })
      .catch(e => {
        console.log(e);
      });
    }
    
    // Save Asset List
    AssetsListService.update(id,data)
      .then(response => {
        swal("Saved!", "Your data succes Updated!", "success");
        navigate("/assets-list");
      })
      .catch(e => {
        console.log(e);
      });
    
  }
  useEffect(() => {
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    
    AssetsListService.getId(id)
    .then(response => {
      setbodyAsset(response.data.data)
    })
    .catch(e => {
      console.log(e);
    })
    var params = {
      type:"",
      search:""
    }
    EmissionFactorService.getAll("","")
    .then(res => {
      setEmissionFactor(res.data.data);
    }).catch(err => console.log(err));
    
  }, [])
  // console.log(EmissionFactor)


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Assets List" link_title="/assets-list" breadcrumbItem="Edit Assets List" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Name of Asset</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="name"
              name="name"
              value={bodyAsset.name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Type</Label>
            <select 
              value={bodyAsset.type} 
              onChange={handleInputChange}
              id="type"
              name="type"
              className="form-select">
                  <option value=""></option>

                  {
                    types?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw}
                        // onChange={Handlefilter}
                        >{raw}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Quantity</Label>
            <Input 
              className="form-control" 
              type="number" 
              id="quantity"
              name="quantity"
              value={bodyAsset.quantity}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Installed At</Label>
            <Input 
              className="form-control" 
              type="datetime-local" 
              id="installed_at"
              name="installed_at"
              value={bodyAsset.installed_at? bodyAsset.installed_at.replace(":00+07:00","") : bodyAsset.installed_at}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Lifetime Day</Label>
            <Input 
              className="form-control" 
              type="number" 
              id="lifetime_day"
              name="lifetime_day"
              value={bodyAsset.lifetime_day}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Supplier</Label>
            <select 
              value={bodyAsset.supplier_id} 
              onChange={handleInputChange}
              id="supplier_id"
              name="supplier_id"
              className="form-select">
                  <option></option>
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Area</Label>

            <select 
              value={bodyAsset.area_id} 
              onChange={handleInputChange}
              id="area_id"
              name="area_id"
              className="form-select">
                  <option value=""></option>
                  {
                    area?.map((raw, index)=>(
                      <option 
                        key={raw.id} 
                        value={raw.id}
                        >{raw.name}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Emission Factor</Label>
            <select 
              value={bodyAsset.emission_factor_id} 
              onChange={handleInputChange}
              id="aemission_factor_id"
              name="emission_factor_id"
              className="form-select">
                  <option value=""></option>
                  {
                    EmissionFactor?.map((data, index)=>(
                      <option 
                        key={data.id} 
                        value={data.id}
                        >{data.name}
                      </option>

                    ))
                  }
            </select>
          </div>

          <div className="text-sm-end">
            <Link to={"/assets-list"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            <button 
              type="button"
              onClick={() => {
                save();
              }}
              className="btn btn-success">
              Update
            </button>
          </div>
          <br />
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditAsset;
