import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = (year:any,area_id:any) => {
  return http.get(`/carbon/v1/threshold-details?year=${year}&area_id=${area_id}`, { headers: authHeader() });
};
// const getAll = () => {
//     return http.get("/carbon/v1/threshold-details", { headers: authHeader() });
//   };

const getId = (id:any) => {
  return http.get(`/carbon/v1/rbac/group/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/threshold", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/threshold/${id}`, data, { headers: authHeader() });
};

const remove = (year:string,id:any) => {
  return http.delete(`/carbon/v1/threshold?year=${year}&area_id=${id}`, { headers: authHeader() });
};

const ThresholdSettingService = {
  getAll,
  getId,
  create,
  update,
  remove
};

export default ThresholdSettingService;
