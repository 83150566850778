import http from "../default";
import authHeader from "./AuthHeaderService";

// const getAll = (type:string, search:string) => { 
//   return http.get(`/carbon/v1/emission-factor?type=${type}&search=${search}`,{ headers: authHeader() });
// };
const getAll = (tenant_id:string, page_destination:string, search:string) => {
  return http.get(`/carbon/v1/embedded-link-tenant?tenant_id=${tenant_id}&page_destination=${page_destination}&search=${search}`, { headers: authHeader() });
};
const getPageDestination = (tenant_id:string) => {
  return http.get(`/carbon/v1/embedded-link-tenant/available-dest?tenant_id=${tenant_id}`, { headers: authHeader() });
};
const getId = (id:any) => {
  return http.get(`/carbon/v1/embedded-link-tenant/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/embedded-link-tenant", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/embedded-link-tenant/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/embedded-link-tenant/${id}`, { headers: authHeader() });
};

const EmbeddedLinkTenant = {
  getAll,
  getPageDestination,
  create,
  update,
  remove,
  getId
};

export default EmbeddedLinkTenant;
