import React, { useState } from "react";
import { Link } from "react-router-dom";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// Redux Store
import { showRightSidebarAction } from "../../store/actions";

//import component
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import LightDark from "../CommonForBoth/Menus/LightDark";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";

//import images
import logoSvg from "../../assets/images/logo-sm.svg";
import logo_musa from "../../assets/images/musagreen_header_logo-3.png";
import logo_jababeka from "../../assets/images/logo-jababeka.png"
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

// Style Component Custom
import "./styles.css";

//redux
import { useSelector, useDispatch } from "react-redux";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { layoutMode, showRightSidebar } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.ShowRightSidebar
  }));

  const [search, setsearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(true);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box ">
              <Link to="/home" className="logo logo-dark">
                <span className="logo-sm">
                  {/* <img src={logo_jababeka} alt="" height="20" /> */}
                  <span className="logo-txt color-text-musa">CP</span>

                </span>
                <span className="logo-lg ">
                  {/* <img src={logo_jababeka} alt="" height="20" /> */}
                  <span className="logo-txt color-text-musa">Carbon Platform</span>
                </span>
              </Link>

              <Link to="/home" className="logo logo-light">
                <span className="logo-sm">
                  {/* <img src={logo_jababeka} alt="" height="20" /> */}
                </span>
                <span className="logo-lg">
                  {/* <img src={logo_jababeka} alt="" height="20" /> */}
                  <span className="logo-txt text-success">Carbon Platform</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

          </div>
          <div className="d-flex">
            <div className=" d-inline-block ms-2 df-musa">
              <span className="logo-lg">
                <span className="color-musa">powered by </span>
                <img src={logo_musa} style={{filter:"blur(10px)"}} alt="" height="70" />{" "}
              </span>
            </div>

            
            
            <NotificationDropdown />
            <div
              // onClick={() => {
              //   dispatch(showRightSidebarAction(!showRightSidebar));
              // }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <Icon name="settings" className="icon-lg" />
              </button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
