import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import {useParams} from 'react-router-dom';


import AssetsListService from '../../../api/service/AssetsListService';
import EmissionFactorService from '../../../api/service/EmissionFactorService';

import IndustrialService from '../../../api/service/IndustrialService'
import TenantService from '../../../api/service/TenantService'

const EditTenant = () => {
  document.title = "Assets List | Carbon Platform";
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  
  const initCreate = {
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0,
    business_sector: "",
    industrial_park_id: ""
  }
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [status_save, setstatus_save] = useState(false);

  const {id} = useParams();
  const navigate = useNavigate();

  // Set Validation
  useEffect(()=>{
    if (bodyAsset.name!="" && bodyAsset.legal_name!="" && bodyAsset.industrial_park_id) {
      setstatus_save(true);
    }else{
      setstatus_save(false);
    }
  },[bodyAsset.name, bodyAsset.legal_name, bodyAsset.industrial_park_id])

  useEffect(() => {
    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));

    TenantService.getId(id)
    .then(response => {
      setbodyAsset(response.data.data)
      
    })
    .catch(e => {
      console.log(e);
    });
  }, [])
 

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    var data = {
      name: bodyAsset.name,
      legal_name: bodyAsset.legal_name,
      head_count: bodyAsset.head_count,
      location_latitude: bodyAsset.location_latitude,
      location_longitude: bodyAsset.location_longitude,
      location_country: bodyAsset.location_country,
      location_area_ha: bodyAsset.location_area_ha,
      business_sector: bodyAsset.business_sector,
      industrial_park_id: bodyAsset.industrial_park_id
      
    }
    console.log(data)
    TenantService.update(id,data)
    .then(response => {
      swal("Saved!", "Your data succes Updated!", "success");
      navigate("/site-management/tenant");
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Site Management" link_title="/site-management/tenant" breadcrumbItem="Edit Tenant" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Name</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="name"
              name="name"
              value={bodyAsset.name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Legal Name</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="legal_name"
              name="legal_name"
              value={bodyAsset.legal_name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Bussines Sector</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="business_sector"
              name="business_sector"
              value={bodyAsset.business_sector}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
            <select 
              value={bodyAsset.industrial_park_id} 
              onChange={handleInputChange}
              placeholder="Industrial Park"
              id="industrial_park_id"
              name="industrial_park_id"
              className="form-select">
                  <option value="">Select Industrial Park</option>
                  {
                    Industrial?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw.id}
                        >{raw.legal_name}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Head Count</Label>
            <Input 
              className="form-control" 
              type="number" 
              id="head_count"
              name="head_count"
              value={bodyAsset.head_count}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Row>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">Location Latitude</Label>
                <Input 
                  className="form-control" 
                  type="number" 
                  id="location_latitude"
                  name="location_latitude"
                  value={bodyAsset.location_latitude}
                  onChange={handleInputChange}
                  />
              </Col>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">Location Longitude</Label>
                <Input 
                  className="form-control" 
                  type="number" 
                  id="location_longitude"
                  name="location_longitude"
                  value={bodyAsset.location_longitude}
                  onChange={handleInputChange}
                  />
              </Col>
              
            </Row>
          </div>
          <div className="mb-3">
            <Row>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">Location Country</Label>
                <Input 
                  className="form-control" 
                  type="text" 
                  id="location_country"
                  name="location_country"
                  value={bodyAsset.location_country}
                  onChange={handleInputChange}
                  />
              </Col>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">Location Area Ha</Label>
                <Input 
                  className="form-control" 
                  type="number" 
                  id="location_area_ha"
                  name="location_area_ha"
                  value={bodyAsset.location_area_ha}
                  onChange={handleInputChange}
                  />
              </Col>
              
            </Row>
          </div>
          <div className="text-sm-end">
            <Link to={"/emission-factor"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            <button 
              type="button"
              disabled={status_save ? false : true}
              onClick={() => {
                save();
              }}
              className="btn btn-success">
              Update
            </button>
          </div>
          <br />
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditTenant;
