import { useState } from "react"

const useObjectState = <T,>(state: T) => {
  const [data, setData] = useState<T>(state)
  const update = (data: Partial<T>) =>
    setData((last) => ({
      ...last,
      ...data,
    }))

  return [data, update] as [T, (data: Partial<T>) => void]
}

export default useObjectState
