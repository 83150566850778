import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import {useParams} from 'react-router-dom';
import type { DatePickerProps } from 'antd';
import { DatePicker, Space, Flex } from 'antd';

import AssetsListService from '../../../api/service/AssetsListService';
import EmissionFactorService from '../../../api/service/EmissionFactorService';
import useObjectState from "../../../api/service/useObjectState";
import IndustrialService from '../../../api/service/IndustrialService';
import TenantService from '../../../api/service/TenantService';
import ThresholdSettingService from '../../../api/service/ThresholdSettingService';

const EditEmissionFactor = () => {
    document.title = "Threshold Setting | Carbon Platform";
    const initEmissionFactor = {
        id :"",
        name : "",
        type : "",
        emission_factor_value :"",
        emission_factor_unit :"",
        reference:"",
        reference_link:"" 
    }
    const defaultMonth = {
        threshold_id:"",
        month_id:"",
        month : "",
        lower_threshold : 0,
        upper_threshold : 0,
        unit : ""
    }
    const initCreate = {
        industrial_id:"",
        tenant_id : "",
        area_id:"",
        year :"",
        threshold_month : [defaultMonth],
        name:"",
        type:"",
        emission_factor_value:0,
        emission_factor_unit:"",
        reference:"",
        reference_link:""
    }
    const initArea = {
        id: "",
        name: "",
        type: "",
    }
    const initTenant = {
        id: "",
        name: "",
        legal_name: "",
        head_count: 0,
        location_latitude: 0,
        location_longitude: 0,
        location_country: "",
        location_area_ha: null,
        business_sector: "",
        industrial_park_id: ""
    }
    const initIndustrial = {
        id: "",
        name: "",
        legal_name: "",
        head_count: 0,
        location_latitude: 0,
        location_longitude: 0,
        location_country: "",
        location_area_ha: 0
    }
    const initMonthThreshold = {
        threshold_id:"",
        date_month : "",
        lower_threshold : 0,
        upper_threshold : 0,
        unit : ""
    }
    const initThresholdSetting = {
        year : "",
        area_id : "",
        monthly_threshold : [initMonthThreshold]
    }
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);

  // const [bodyAsset1, setbodyAsset1] = useState(initCreate);
//   const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [bodyAsset_rl, setbodyAsset_rl] = useState("");
  const [SettingThreshold, setSettingThreshold] = useState([initThresholdSetting]);

  const {year,area_id} = useParams();
  // console.log("get details", area_id,"---",year)
  const navigate = useNavigate();
  const factor_unit = ["GWP","kgN2O/kgNremoved","kgCH4/kgCODremoved","kgCH4/kgBODremoved","tCO2/L","tCO2/kWh","tCO2eq/kg"];
  const months = ["January", "February", "March","April", "May", "June", "July", "August", "September","October","November","December"];
  // console.log(SettingThreshold)
  // bodyAsset.threshold_month = []
  const [bodyAsset, setbodyAsset] = useObjectState({
    ...initCreate,
    threshold_month: [defaultMonth],
  });
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [FilterTenant, setFilterTenant] = useState([initTenant]);

  const [Tenant, setTenant] = useState([initTenant]);
//   const [area, setArea] = useState([initArea]);
  const [areaselect, setAreaselect] = useState([initArea]);
  const [year_select_current, setyear_select_current] = useState("")
  const [year_select, setyear_select] = useState("")
  
  useEffect(() => {
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setAreaselect(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // var parse_year = year
    ThresholdSettingService.getAll(year ,area_id)
    .then(res => {
      // setTypes(res.data.data);
      setSettingThreshold(res.data.data)
      setbodyAsset({area_id:res.data.data[0].area_id})
      setyear_select_current(res.data.data[0].year)
      
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // console.log(area, types)
    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));

    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));
  }, [])
  
  useEffect(() => {
    bodyAsset.threshold_month = []
    var objMonth = [
      {id:"01",month:"January"},
      {id:"02",month:"February"},
      {id:"03",month:"March"},
      {id:"04",month:"April"},
      {id:"05",month:"May"},
      {id:"06",month:"June"},
      {id:"07",month:"July"},
      {id:"08",month:"August"},
      {id:"09",month:"September"},
      {id:"10",month:"October"},
      {id:"11",month:"November"},
      {id:"12",month:"December"},
    ]
    for (let i = 0; i < objMonth.length; i++) {
      var check_status = false
      for (let j = 0; j < SettingThreshold[0].monthly_threshold.length; j++) {
        var month = SettingThreshold[0].monthly_threshold[j]?.date_month.split("-")
        if (month[1]==objMonth[i].id) {
          check_status=true;
          bodyAsset.threshold_month.push(
            {
              threshold_id: SettingThreshold[0].monthly_threshold[j]?.threshold_id,
              month_id : objMonth[i].id,
              month : objMonth[i].month,
              lower_threshold : SettingThreshold[0].monthly_threshold[j]?.lower_threshold,
              upper_threshold : SettingThreshold[0].monthly_threshold[j]?.upper_threshold,
              unit : SettingThreshold[0].monthly_threshold[j]?.unit
            })
        }
      }
      if (check_status==false) {
          bodyAsset.threshold_month.push(
            {
                threshold_id:"",
                month_id : objMonth[i].id,
                month : objMonth[i].month,
                lower_threshold : 0,
                upper_threshold : 0,
                unit : ""
            })
      }
    }
    
  }, [SettingThreshold])
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(date, dateString);
    setyear_select(dateString.toString())
  };
  const editParam = (
    idx: number,
    data: Partial<{
      month: string;
      lower_threshold: number;
      upper_threshold: number;
      unit: string;
    }>
  ) => {
    const arr = [...bodyAsset.threshold_month];

    arr[idx] = { ...arr[idx], ...data };

    setbodyAsset({threshold_month:arr})
  };
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    for (let index = 0; index < bodyAsset.threshold_month.length; index++) {
      
      if (bodyAsset.threshold_month[index].lower_threshold!=0 && bodyAsset.threshold_month[index].upper_threshold && bodyAsset.threshold_month[index].unit!="") {
        var get_year = "";
        if (year_select=="") {
          get_year = year_select_current;
        }else{
          get_year = year_select;
        }
        var parse_date = get_year + "-" + bodyAsset.threshold_month[index].month_id + "-01";
        var data = {
          date_month :parse_date,
          lower_threshold : bodyAsset.threshold_month[index].lower_threshold,
          upper_threshold : bodyAsset.threshold_month[index].upper_threshold,
          unit : bodyAsset.threshold_month[index].unit,
          area_id : bodyAsset.area_id
        }
        if (bodyAsset.threshold_month[index].threshold_id!="") {
          ThresholdSettingService.update(bodyAsset.threshold_month[index].threshold_id,data)
          .then(response => {
            console.log(response)
          })
          .catch(e => {
            console.log(e);
          });
        }else {
          ThresholdSettingService.create(data)
          .then(response => {
            console.log(response)
          })
          .catch(e => {
            console.log(e);
          });
        }
        
        
      }
      
    }
    swal("Saved!", "Your data succes Updated!", "success");
    navigate("/setting-threshold");
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Setting Threshold" link_title="/setting-threshold" breadcrumbItem="Edit Setting Threshold" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
            <select 
            value={bodyAsset.industrial_id} 
            onChange={handleInputChange}
            placeholder="Industrial Park"
            id="industrial_id"
            name="industrial_id"
            className="form-select">
                <option value="">Select Industrial Park</option>
                {
                    Industrial?.map((raw, index)=>(
                    <option 
                        key={index} 
                        value={raw.id}
                        >{raw.legal_name}
                    </option>

                    ))
                }
            </select>
        </div>
        <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>
            <select 
            value={bodyAsset.tenant_id} 
            onChange={handleInputChange}
            placeholder="Tenant"
            id="tenant_id"
            name="tenant_id"
            className="form-select">
                <option value="">Select Tenant</option>
                {
                    FilterTenant?.map((raw, index)=>(
                    <option 
                        key={index} 
                        value={raw.id}
                        >{raw.legal_name}
                    </option>

                    ))
                }
            </select>
        </div>
        <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Area</Label>

            <select 
            value={bodyAsset.area_id} 
            onChange={handleInputChange}
            id="area_id"
            name="area_id"
            className="form-select">
                <option value=""> Select Area</option>
                {
                    areaselect?.map((raw, index)=>(
                    <option 
                        key={raw.id} 
                        value={raw.id}
                        >{raw.name}
                    </option>

                    ))
                }
            </select>
        </div>
        <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Year - (Previously selected year : {year_select_current})</Label>
            <Flex vertical gap="small">
              <DatePicker
              onChange={onChange}
              picker="year" />
            </Flex>
            
        </div>
        <hr />
        <div className="mb-3">
            <h5>Target Per Month</h5>
        </div>
        <div className="mb-3">
            <Row>
            <Col>
                <Label htmlFor="example-text-input" className="form-Label">Month</Label>
            </Col>
            <Col>
            <Label htmlFor="example-text-input" className="form-Label">Lower Threshold</Label>
            </Col>
            <Col>
            <Label htmlFor="example-text-input" className="form-Label">Upper Threshold</Label>
            </Col>
            <Col>
            <Label htmlFor="example-text-input" className="form-Label">Unit</Label>
            </Col>
            </Row>
            {
            bodyAsset.threshold_month.map((threshold_month,idx)=>(
                <Row key={idx} style={{marginTop:"10px"}}>
                <Col>
                    <Input 
                    className="form-control bg-light-subtle" 
                    type="text" 
                    id="month"
                    name="month"
                    // value={raw}
                    disabled={true}
                    // value={bodyAsset.usage_unit}
                    // onChange={handleInputChange}
                    onChange={({ currentTarget: e }) => editParam(idx, { month: e.value })}
                    value={threshold_month.month}
                    />
                </Col>
                <Col>
                    <Input 
                    className="form-control" 
                    type="number" 
                    id="lower_threshold"
                    name="lower_threshold"
                    step="any"
                    onChange={({ currentTarget: e }) => editParam(idx, { lower_threshold: Number(e.value) })}
                    value={threshold_month.lower_threshold}
                    // value={bodyAsset.usage_unit}
                    // onChange={handleInputChange}
                    />
                </Col>
                <Col>
                    <Input 
                    className="form-control" 
                    type="number" 
                    id="upper_threshold"
                    name="upper_threshold"
                    // value={bodyAsset.usage_unit}
                    // onChange={handleInputChange}
                    onChange={({ currentTarget: e }) => editParam(idx, { upper_threshold: Number(e.value) })}
                    value={threshold_month.upper_threshold}


                    />
                </Col>
                <Col>
                    <Input 
                    className="form-control" 
                    type="text" 
                    id="unit"
                    name="unit"
                    // value={bodyAsset.usage_unit}
                    // onChange={handleInputChange}
                    onChange={({ currentTarget: e }) => editParam(idx, { unit: e.value })}
                    value={threshold_month.unit}
                    />
                </Col>
                </Row>
                
            ))
            }
            
            
            
        </div>
          <div className="text-sm-end">
            <Link to={"/setting-threshold"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            <button 
              type="button"
              onClick={() => {
                save();
              }}
              className="btn btn-success">
              Update
            </button>
          </div>
          <br />
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditEmissionFactor;
