import React , {useState, useEffect} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
   } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import { isNull } from "lodash";
import EmbeddedLinkTenant from "../../../api/service/EmbeddedLinkTenant";
// const fs = require("fs");
// import error from "../../../assets/images/error-img.png";
import error from "../../../assets/images/error-img.png";


const ReportSummary = () => {
  
  const initEmbeddedLink = {
    id: "",
    name: "",
    link: "",
    page_destination: "",
    updated_by: "",
    updated_at: "",
    tenant_id: "",
    tenant_name: ""
  }

  document.title = "Summary Report | Carbon Platform";
  const [customMR, setcustomMR] = useState("");
  const [embedchartTenant, setembedchartTenant] = useState([initEmbeddedLink]);
  var tenant_user = localStorage.getItem('tenant_id') || "";
  const [delete_status, setdelete_status] = useState(0);
  const [status_save, setstatus_save] = useState(0);

  // localStorage.setItem('CS1','default');
  useEffect(() => {
    EmbeddedLinkTenant.getAll(tenant_user,"Summary","")
    .then(res => {
      try {
        setembedchartTenant(res.data.data)
        
      } catch (error) {
        setembedchartTenant([]);
      }
      // console.log(dataAssetList)
      
    }).catch(err => console.log(err));
   
  }, [ delete_status, status_save]);
  // console.log(embedchartTenant)
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Report" link_title="/report/cdp" breadcrumbItem="Summary Report" />
          <Row>
            <div>
              {
                embedchartTenant.length>0 ?
                  <iframe
                  width="100%"
                  height="920"
                  seamless
                  frameBorder="0"
                  scrolling="auto"
                  src={`${embedchartTenant[0].link}`}

                >
                </iframe>
                :
                <>
                  <Col lg={12}>
                    <div className="text-center mb-5">
                      <h1 className="display-1 fw-semibold">
                        4<span className="text-primary mx-2">0</span>4
                      </h1>
                      <h4 className="text-uppercase">Sorry, the report is not configured yet</h4>
                      <div className="mt-3 ">
                        <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={4}>
                          <Card className="pt-3 pb-3 border border-primary text-center text-success fw-medium display-8">
                            Request your admin to set it up
                          </Card>
                        </Col>
                        </Row>
                        
                      </div>
                    </div>
                  </Col>
                  <div className="row justify-content-center">
                      <div className="col-md-10 col-xl-8">
                        <div>
                          <img src={error} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                </>
              }
              
            </div>
            
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportSummary;
