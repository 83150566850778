import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
// import swal from 'sweetalert';
import Swal from 'sweetalert2';

import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Input,
  Label,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button } from "reactstrap";
  import ReactPaginate from "react-paginate";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";
import AssetsListService from '../../api/service/AssetsListService';
import { use } from "echarts";
import moment from "moment";


const AssetsList = () => {
  document.title = "Assets List | Carbon Platform";

  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const  initAssetList = {
    id: "",
    name: "",
    type: "",
    quantity: 0,
    installed_at: "",
    supplier: {
        id: "",
        name: "",
        legal_name: "",
        origin_country: ""
    },
    area: {
      id: "",
      name: "",
      type: "",
    },
    emission_factor: {
      id: "",
      name: "",
      type: "",
    },
    status: false

  }
  const initCreate = {
    name:"",
    type:"",
    quantity:0,
    installed_at:"",
    lifetime_day:0,
    supplier_id:"",
    area_id:""
  }
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);
  const [dataAssetList, setdataAssetList] = useState([]);
  // const [dataAssetList, setdataAssetList] = useState(initAssetList);
  const [filter_area , setFilter] = useState("")
  const [filter_type , setFiltertype] = useState("")
  const [search_name , setSearchName] = useState("")

  // console.log(search_name)

  const [bodyAsset, setbodyAsset] = useState(initCreate);
  
  // const [currentData, setCurrentData] = useState([]);
  const [currentData, setCurrentData] = useState([initAssetList]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);

  const [delete_status, setdelete_status] = useState(false);
  const navigate = useNavigate();
  // console.log(filter)

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    var data = {
      name : bodyAsset.name,
      type : bodyAsset.type,
      quantity: bodyAsset.quantity,
      installed_at: bodyAsset.installed_at + ":00.000+07:00",
      lifetime_day: bodyAsset.lifetime_day,
      supplier_id: null,
      area_id: bodyAsset.area_id
    }
    // console.log(data)
    AssetsListService.create(data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setbodyAsset(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this Asset!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        AssetsListService.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your Asset has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
  };
  useEffect(() => {
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    // console.log(area, types)
  }, [])

  useEffect(()=>{
    var params = {
      area_id :filter_area,
      asset_type:filter_type,
      search:search_name
    }
    AssetsListService.getAll(filter_area, filter_type, search_name)
    .then(res => {
      try {
        setdataAssetList(res.data.data.asset_component_list);
        
      } catch (error) {
      setdataAssetList([]);
      }
      // console.log(dataAssetList)
      
    }).catch(err => console.log(err));
  },[search_name, filter_area,filter_type, modal_xlarge, delete_status])
  // setdataAssetList([]);
  console.log(dataAssetList.length)

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  function tog_edit() {
    setmodal_edit(!modal_xlarge);
    removeBodyCss();
  }
  
  useEffect(() => {
    const endOffset = dataOffset + 10;
    setCurrentData(dataAssetList.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(dataAssetList.length / 10));
  }, [dataAssetList, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % dataAssetList.length;
    setDataOffset(newOffset);
  }; 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Assets List" link_title="assets-list" breadcrumbItem="Assets List" />
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add Entry
                </button>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="4">
                    </Col>
                    <Col sm="8">
                    <Row>
                      <Col sm='6'>
                        <div className="mb-0">
                          <Input 
                            className="form-control" 
                            type="text" 
                            id="name"
                            name="name"
                            placeholder="Search Name"
                            value={search_name}
                            onChange={({ currentTarget }) => setSearchName(currentTarget.value,)}
                            // onChange={handleInputChange}
                            />
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="text-sm-end">
                          <select 
                            value={filter_area} 
                            // onChange={Handlefilter}
                            onChange={({ currentTarget }) => setFilter(currentTarget.value,)}
                            className="form-select">
                              <option value="">Area</option>
                                {
                                  area?.map((raw, index)=>(
                                    <option 
                                      key={raw.id} 
                                      value={raw.id}
                                      // onChange={Handlefilter}
                                      >{raw.name}
                                    </option>

                                  ))
                                }
                          </select>
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="text-sm-end">
                          <select 
                              // style={{width:"60%", display:"inline"}}
                              value={filter_type} 
                              // onChange={Handlefilter}
                              onChange={({ currentTarget }) => setFiltertype(currentTarget.value,)}
                              className="form-select">
                                <option value="">Type</option>
                                  {
                                    types?.map((raw, index)=>(
                                      <option 
                                        key={index} 
                                        value={raw}
                                        // onChange={Handlefilter}
                                        >{raw}
                                      </option>

                                    ))
                                  }
                            </select>
                          
                        </div>
                      </Col>
                      </Row>
                      
                      <div>
                        <Modal
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              Add Asset List
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          {/* Modal Content */}
                          <div className="modal-body">
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Name of Asset</Label>
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="name"
                                name="name"
                                value={bodyAsset.name}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Type</Label>
                              <select 
                                value={bodyAsset.type} 
                                onChange={handleInputChange}
                                id="type"
                                name="type"
                                className="form-select">
                                    <option value=""></option>

                                    {
                                      types?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw}
                                          // onChange={Handlefilter}
                                          >{raw}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Quantity</Label>
                              <Input 
                                className="form-control" 
                                type="number" 
                                id="quantity"
                                name="quantity"
                                value={bodyAsset.quantity}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Installed At</Label>
                              <Input 
                                className="form-control" 
                                type="datetime-local" 
                                id="installed_at"
                                name="installed_at"
                                value={bodyAsset.installed_at}
                                onChange={handleInputChange}
                                />
                            </div>

                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Lifetime Day</Label>
                              <Input 
                                className="form-control" 
                                type="number" 
                                id="lifetime_day"
                                name="lifetime_day"
                                value={bodyAsset.lifetime_day}
                                onChange={handleInputChange}
                                />
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Supplier</Label>
                              <select 
                                value={bodyAsset.supplier_id} 
                                onChange={handleInputChange}
                                id="supplier_id"
                                name="supplier_id"
                                className="form-select">
                                    <option></option>
                              </select>
                            </div>
                            <div className="mb-3">
                              <Label htmlFor="example-text-input" className="form-Label">Area</Label>

                              <select 
                                value={bodyAsset.area_id} 
                                onChange={handleInputChange}
                                id="area_id"
                                name="area_id"
                                className="form-select">
                                    <option value=""></option>
                                    {
                                      area?.map((raw, index)=>(
                                        <option 
                                          key={raw.id} 
                                          value={raw.id}
                                          >{raw.name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>

                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Cancel
                              </button>
                              {
                                <button 
                                  type="button"
                                  onClick={() => {
                                    save();
                                  }}
                                  className="btn btn-success">
                                  Save Asset
                                </button>
                                }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                      
                    </Col>
                  </Row>
                  <br />
                  <Row>
                  <div className="table-responsive">
                    <Table className="table-bordered table mb-0">
                      <thead className="bg-success text-white">
                        <tr>
                          <th>Name</th>
                          <th>Area</th>
                          <th>Type</th>
                          <th>Emission</th>
                          <th>Quantity</th>
                          <th>Supplier</th>
                          <th>Installed At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentData.length >0 ? (
                            currentData.map((raw,index)=>(
                              // dataAssetList.map((raw,index)=>(
                              <tr key={raw.id}>
                                <td>{raw.name}</td>
                                <td>{raw.area.name}</td>
                                <td>{raw.type}</td>
                                <td>{raw.emission_factor.name}</td>
                                <td>{raw.quantity}</td>
                                <td>{raw.supplier.name}</td>
                                <td>{raw.installed_at ? moment(raw.installed_at).format("YYYY-MM-DD HH:mm") : ""}</td>
                                <td>
                                <div className="d-flex gap-3">
                                  
                                  {/* <Link className="text-primary" to={"/data-collection/detail/"+raw.id}>
                                    <i className="bx bx-download label-icon"></i>
                                  </Link> */}
                                  <Link className="text-success" to={"/assets-list/edit/"+raw.id}
                                  >
                                    <i
                                      className=" bx bx-pencil font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </Link>
                                  <a className="text-danger" href="#"
                                    onClick={() => {handleDelete(raw.id)}} 
                                  >
                                    <i
                                      className=" bx bx-trash-alt font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </a>
                                  
                                </div>
  
                                </td>
                              </tr>
                            ))
                          ) :
                          (
                            <tr>
                              <td colSpan={8}>No Data Asset</td>
                            </tr>
                          )
                          
                        }
                        
                        
                      </tbody>
                    </Table>
                     <hr />
                  </div>
                  <div className="text-sm-end">
                    <div className="justify-content-end pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                  
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AssetsList;
