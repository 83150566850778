import http from "../default";
import authHeader from "./AuthHeaderService";

// const getAll = (type:string, search:string) => { 
//   return http.get(`/carbon/v1/emission-factor?type=${type}&search=${search}`,{ headers: authHeader() });
// };
const getAll = (search:string ,industrial_park_id:string, tenant_id:string) => {
  return http.get(`/carbon/v1/rbac/user?search=${search}&industrial_park_id=${industrial_park_id}&tenant_id=${tenant_id}`, { headers: authHeader() });
};

const getId = (id:any) => {
  return http.get(`/carbon/v1/rbac/user/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/rbac/user", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/rbac/user/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/rbac/user/${id}`, { headers: authHeader() });
};

const UserService = {
  getAll,
  create,
  getId,
  update,
  remove
};

export default UserService;
