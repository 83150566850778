import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = (type:string, search:string) => { 
  return http.get(`/carbon/v1/emission-factor?type=${type}&search=${search}`,{ headers: authHeader() });
};


const getId = (id:any) => {
  return http.get(`/carbon/v1/emission-factor/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/emission-factor", data, { headers: authHeader() });
};

const createEmissionMapping = (data:any) => {
  return http.post("/carbon/v1/emission-factor-mapping", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/emission-factor/${id}`, data, { headers: authHeader() });
};

const updateEmissionMapping = (id:any, data:any) => {
  return http.put(`/carbon/v1/emission-factor-mapping/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/emission-factor/${id}`, { headers: authHeader() });
};

const EmissionFactorService = {
  getAll,
  create,
  remove,
  getId,
  update,
  createEmissionMapping,
  updateEmissionMapping
};

export default EmissionFactorService;
