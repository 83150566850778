import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = (area_id:string, asset_type:string, search:string) => {
  return http.get(`/carbon/v1/asset-component-details?area_id=${area_id}&asset_type=${asset_type}&search=${search}`,{ headers: authHeader() });
};
// const getAll = (detail_param:any) => {
//   return http.get("/carbon/v1/asset-component-details",{params:detail_param});
// };

const getAssetTypes = () =>{
  return http.get("/carbon/v1/asset-types", { headers: authHeader() });
}

const getArea = () =>{
  return http.get("/carbon/v1/area", { headers: authHeader() });
}

const getAreaFilter = (tenant_id:string) =>{
  return http.get(`/carbon/v1/area?tenant_id=${tenant_id}`, { headers: authHeader() });
}
const getId = (id:any) => {
  return http.get(`/carbon/v1/asset-component/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/asset-component", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/asset-component/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/asset-component/${id}`, { headers: authHeader() });
};

const AssetsListService = {
  getAll,
  getArea,
  getAssetTypes,
  create,
  remove,
  getId,
  update,
  getAreaFilter
};

export default AssetsListService;
