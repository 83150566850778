import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../components/Common/DataCollection/TableContainer";
import { Link ,useNavigate } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from "reactstrap";
// import swal from 'sweetalert';
import Swal from 'sweetalert2'; 
import { DateRangePicker, Stack } from 'rsuite';

import Icon from "@ailibs/feather-react-ts";

import {
  addInvoiceDetail,
  getInvoices as onGetInvoices,
  invoiceDelete,
  invoiceUpdate,
} from "../../store/actions";


import * as Yup from "yup";

import DataCollectionService from '../../api/service/DataCollectionService';
import TenantService from '../../api/service/TenantService';

//redux
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import DeleteModal from "../Calendar/DeleteModal";
import moment from "moment";

import ReactPaginate from "react-paginate";
import { tr } from "date-fns/locale";
import { setTimeout } from "timers";



const DataCollection = () => {
  document.title = "Data Collection | Carbon Platform";

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);

  const initialCollectDataState = {
    id: "",
    created_at:"",
    date_start: "",
    date_end: "",
    file_name:"",
    uploaded_by: "",
    file_link: "",
    tenant_name: "",
    tenant_id: "",
    calculated:false
  };
  const initialTenantState = {
    id: "",
    name: "",
    legal_name: "",
    head_count:"",
    location_latitude: Number,
    location_longitude: Number,
    location_country: "",
    location_area_ha: "",
    business_sector:"",
    industrial_park_id:""
  };

  const initPreviewEmission = {
    asset_usage_id: "",
    asset_component_name: "",
    measured_at: "",
    usage_value: 0,
    emission_factor_name: " ",
    emission_factor_value: 0,
    emission_factor_unit: "",
    emission_value: 0,
    emission_unit: ""
  }

  const [CollectData, setCollectData] = useState([initialCollectDataState]);
  const [Tenants, setTenants] = useState([initialTenantState]);
  const [filter , setFilter] = useState("");
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);

  const [currentData, setCurrentData] = useState([initialCollectDataState]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  // Preview Data Calculation Emission
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [getPreviewEmission, setPreviewEmission] = useState([initPreviewEmission]);
  const [toast, setToast] = useState(false);
  const [delete_status, setdelete_status] = useState(false);

  var start = "";
  var end = "";
  if(FilterDate!=null){
    start = moment(FilterDate[0]).format("YYYY-MM-DD");
    end = moment(FilterDate[1]).format("YYYY-MM-DD")
    if (start==end) {
      start=""
      end = ""
    }
  }
  else {
    start=""
    end = ""
  }
  
  // const [visible, setVisible] =  useState(true)
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure that you want to delete this file!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        DataCollectionService.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
    
  };
  const Handlefilter = (e:any) => {
    setFilter(e.target.value);
  };

  // Get Data 
  useEffect(() => {
    TenantService.getAll().then(res => {
      setTenants(res.data.data);
    }).catch(err => console.log(err));

    DataCollectionService.getAll(start,end,filter)
    .then(response => {
      setCollectData(response.data.data.emission_datas);
    })
    .catch(e => {
      console.log(e);
    });
  }, [delete_status, filter, FilterDate])

  // Get Data Preview Emission
  useEffect(() => {
    DataCollectionService.getPreviewEmission()
    .then(response => {
      setPreviewEmission(response.data.data)
      // setCollectData(response.data.data.emission_datas);
    })
    .catch(e => {
      console.log(e);
    });
    // console.log(getPreviewEmission)
  }, [modal_xlarge])

  const [tableData, settableData] = useState([initialCollectDataState]);
  
  var tableData1 = CollectData;
  useEffect(() => {
    if (filter) {
      tableData1 = CollectData.filter((data)=>
        data.tenant_id==filter
      )
      settableData(tableData1)
    }
    else{
      settableData(tableData1)
    }
  }, [filter, tableData1])
  
  // console.log("coba filter ",tableData)

  useEffect(() => {
    const endOffset = dataOffset + 5;
    setCurrentData(tableData.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(tableData.length / 5));
  }, [tableData, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 5) % CollectData.length;
    setDataOffset(newOffset);
  };
  
  // console.log(CollectData);
  
  
  // for delete invoice
  const [deleteModal, setDeleteModal] = useState(false);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      Amount: (order && order.Amount) || "",
      date: (order && order.date) || "",
      founder: (order && order.founder) || "",
      id: (order && order.id) || "",
      invoiceId: (order && order.invoiceId) || "",
      status: (order && order.status) || "Paid",
      color: (order && order.color) || "Success",
    },
    validationSchema: Yup.object({
      Amount: Yup.string().required("Please Enter Amount"),
      founder: Yup.string().required("Please Enter Billing Name"),
      invoiceId: Yup.string().required("Please Enter Invoice Id"),
      status: Yup.string().required("Please Enter Status"),
      date: Yup.date().required("Please Enter Date"),
    }),
    onSubmit: (values: any) => {
      if (isEdit) {
        const updateUser = {
          id: values.id ? values.id : 0,
          Amount: values.Amount,
          founder: values.founder,
          date: moment(values.date).format("DD MMM , YYYY"),
          invoiceId: values.invoiceId,
          status: values.status,
          color: values.color,
        };

        // update user
        dispatch(invoiceUpdate(updateUser));
        validation.resetForm();
        // setIsEdit(false);
      } else {
        const invoice = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          Amount: values["Amount"],
          founder: values["founder"],
          date: moment(values["date"]).format("DD MMM, YYYY"),
          invoiceId: values["invoiceId"],
          status: values["status"],
          color: "success",
        };
        // save new user
        dispatch(addInvoiceDetail(invoice));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { invoices } = useSelector((state: any) => ({
    invoices: state.Invoices.invoices,
  }));

  useEffect(() => {
    dispatch(onGetInvoices());
  }, [dispatch]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const SaveCalculationEmission = () => {

    DataCollectionService.saveCalculationEmission(getPreviewEmission)
    .then((response) => {
      console.log(response);
      setmodal_xlarge(false)
      setToast(!toast);
      // navigate("/data-collection");
    })
    .catch((error) => {
      console.log(error);
    });
  }
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Data Collection" breadcrumbItem="" /> */}
          
          <Row>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Data Collection</h4>
              <div className="page-title-right">
                <Breadcrumb className="m-0" >
                  <BreadcrumbItem>
                    <Link to={'/data-collection'}>
                    Data Collection
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    File Upload
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Row>
          <Row>
          <Col sm="8">
            <Link className="btn btn-light waves-effect waves-light" to="/raw-data">
              <Icon size={15} name="file-text" /> Go Back to All Records
            </Link>
            &nbsp;

            <Link className="btn btn-light waves-effect waves-light" to="/form-input">
              <Icon size={15} name="edit-3" /> Switch to Form Input 
            </Link>
            &nbsp;
          </Col>
         
          </Row>
          <br />
          <Row>
            
            <Col lg="12"> 
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="2">
                      <div className="mb-0">
                        <select 
                          value={filter} 
                          onChange={Handlefilter}
                          // onChange={{({ currentTarget }) => setFilter({ ...filter, mil: currentTarget.value, cems: "" })}}

                          className="form-select">
                            <option value="">All Tenant</option>
                              {
                                Tenants?.map((tenant,row)=>(
                                  <option 
                                    key={tenant.id} 
                                    value={tenant.id}
                                    // onChange={Handlefilter}
                                    >{tenant.name}
                                  </option>

                                ))
                              }
                          </select>
                      </div>
                    </Col>
                    <Col sm="10">
                      <div className="text-sm-end">
                      <DateRangePicker
                            onChange={(e:any) => setFilterDate(e)}
                            placement="bottomEnd"
                            placeholder="Periode"
                            style={{ width: 300 }}
                          />
                        &nbsp;&nbsp;
                        <Link className="btn btn-light waves-effect waves-light" to="/data-collection/add">
                          <i className="bx bx-plus me-1"></i> Add Entry
                        </Link>
                        &nbsp;
                        {/* <button
                          type="button"
                          className="btn btn-light waves-effect waves-light"
                        >
                          <i className="bx bx-list-ol me-1"></i> Logs
                        </button>
                        &nbsp; */}

                        <button
                          // onClick={handleInvoiceClick}
                          type="button"
                          className="btn btn-light waves-effect waves-light"
                          onClick={() => {
                            tog_xlarge();
                          }}
                          data-toggle="modal"
                          data-target=".bs-example-modal-xl"
                        >
                          <i className="bx bx-navigation me-1"></i> Generate Emission
                        </button>
                        &nbsp;
                        <a className="btn btn-light waves-effect waves-light" href="https://docs.google.com/spreadsheets/d/1s_933ttsZ0sF2LWlKzAlpS_-wbG_tyujzHSlsnyLzG8/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                          Download Template Files
                        </a>
                        
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                      <div>
                        {/* <Button
                          type="button"
                          color="primary"
                          id="liveToastBtn"
                          onClick={toggleToast}
                        >
                          Show live toast
                        </Button> */}

                        <div
                          className="position-fixed top-0 end-0 p-3"
                          style={{ zIndex: 1051 }}
                        >
                          <Toast isOpen={toast} autohide>
                            <ToastHeader toggle={SaveCalculationEmission} >
                              {/* <img src={logo} className="me-2" height="18" /> */}
                              Emission Calculation is successfully saved. 
                            </ToastHeader>
                            <ToastBody>
                              Go to the &nbsp;
                              <Link className="text-success" to={"/analytics-overview"}>
                                overview page
                              </Link> to see the result!
                            </ToastBody>
                          </Toast>
                        </div>
                      </div>
                    </div>
                      <div>
                        <Modal
                          
                          size="xl"
                          isOpen={modal_xlarge}
                          toggle={() => {
                            tog_xlarge();
                          }}
                          scrollable={true}
                        >
                          <div className="modal-header">
                            <h5
                              className="modal-title mt-0"
                              id="myExtraLargeModalLabel"
                            >
                              Preview Data Emission Calculation
                            </h5>
                            <button
                              onClick={() => {
                                setmodal_xlarge(false);
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="table-responsive">
                              <Table className="table mb-0">
                                <thead className="bg-success text-white">
                                  <tr>
                                    <th>Component Name</th>
                                    <th>Usage Value</th>
                                    <th>Emission Factor Name</th>
                                    <th>Emission Factor Value / Unit</th>
                                    <th>Emission Value / Unit</th>
                                    <th>Measured at</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                  { getPreviewEmission.length!=0 ?
                                    getPreviewEmission.map((raw,index)=>(
                                      <tr key={index}>
                                        <td>{raw.asset_component_name}</td>
                                        <td>{raw.usage_value}</td>
                                        <td>
                                          <div className={"badge badge-soft-" + "primary" + " font-size-12"}>
                                            {raw.emission_factor_name}
                                          </div>
                                          
                                        </td>
                                        <td>{raw.emission_factor_value} ({raw.emission_factor_unit})</td>
                                        <td>{raw.emission_value} ({raw.emission_unit})</td>
                                        <td>{moment(raw.measured_at).format("YYYY-MM-DD")}</td>
                                      </tr>
                                    ))
                                    :
                                    <tr>
                                      <td colSpan={6}>No Data Preview, Please Input New Data to Preview Calculated Emission!</td>
                                    </tr>
                                  }
                                </tbody>
                              </Table>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => setmodal_xlarge(false)}
                              >
                                Close
                              </button>
                              {
                                getPreviewEmission.length!=0 ?
                                <button 
                                  type="button"
                                  onClick={() => {
                                    SaveCalculationEmission();
                                  }}
                                  className="btn btn-success">
                                  Save Calculation
                                </button>
                                :
                                ""
                              }
                              
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <div className="table-responsive">
                    <Table className="table-bordered table mb-0">
                      <thead className="bg-success text-white">
                        <tr>
                          <th>Date Range</th>
                          <th>Company</th>
                          <th>Filename</th>
                          <th>Upload By</th>
                          <th>Upload At</th>
                          <th>Emission Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        { currentData.length!=0 ?
                            currentData.map((raw,index)=>(
                            // CollectData.map((raw,index)=>(
                              <tr key={raw.id}>
                                <td>{moment(raw.date_start).format("YYYY-MM-DD") } - { moment(raw.date_end).format("YYYY-MM-DD")}</td>
                                <td>{raw.tenant_name}</td>
                                <td>
                                  
                                  <div className={"badge badge-soft-" + "primary" + " font-size-12"}>
                                  {raw.file_name}
                                  </div>
                                </td>
                                <td>
                                  <div className={"badge badge-soft-" + "secondary" + " font-size-12"}>
                                    Admin
                                  </div>
                                </td>
                                <td>
                                  <div className={"badge badge-soft-" + "secondary" + " font-size-12"}>
                                    { moment(raw.created_at).format("YYYY-MM-DD HH:mm:ss")}
                                    
                                  </div>
                                </td>
                                <td>
                                  {raw.calculated ? 
                                  <div className={"badge badge-soft-" + "success" + " font-size-12"}>
                                    Generated
                                  </div>
                                  : 
                                  <div className={"badge badge-soft-" + "danger" + " font-size-12"}>
                                    Not Generated
                                  </div>
                                  }
                                </td>
                                <td>
                                <div className="d-flex gap-3">
                                  
                                  {/* <Link className="text-primary" to={"/data-collection/detail/"+raw.id}>
                                    <i className="bx bx-download label-icon"></i>
                                  </Link> */}
                                  <Link className="text-success" to={"/data-collection/detail/"+raw.id}>
                                    <i
                                      className=" bx bx-show font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </Link>
                                  <a className="text-danger" onClick={() => {handleDelete(raw.id)}} >
                                    <i
                                      className=" bx bx-trash-alt font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </a>
                                  
                                </div>

                                </td>
                              </tr>
                            ))
                            :
                            <tr >
                              <td colSpan={7} style={{textAlign:"center"}}>Data Not Avaliable</td>
                            </tr>
                        }
                        
                        
                      </tbody>
                    </Table>
                  </div>
                  <br />
                  <div className="text-sm-end">
                    <div className="justify-content-end pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                  
                  {/* <TableContainer
                    columns={columns}
                    data={invoices}
                    // isAddUserList={true}
                    isGlobalFilter={true}
                    isAddInvoiceList={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  /> */}
                  {/* <TableContainer
                    columns={columns}
                    data={invoices}
                    // isAddUserList={true}
                    isGlobalFilter={true}
                    isAddInvoiceList={true}
                    customPageSize={10}
                    handleInvoiceClick={handleUserClicks}
                  /> */}
                </CardBody>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? "Edit " : "+ Add Entry"}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <Label className="form-label">Invoice ID</Label>
                          <div className="mb-3">
                            <Input
                              name="invoiceId"
                              type="text"
                              placeholder="Insert Invoice Id"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.invoiceId || ""}
                              invalid={
                                validation.touched.invoiceId &&
                                validation.errors.invoiceId
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.invoiceId &&
                            validation.errors.invoiceId ? (
                              <FormFeedback type="invalid">
                                {validation.errors.invoiceId}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Billing Name</Label>
                            <Input
                              name="founder"
                              type="text"
                              placeholder="Insert Billing Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.founder || ""}
                              invalid={
                                validation.touched.founder &&
                                validation.errors.founder
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.founder &&
                            validation.errors.founder ? (
                              <FormFeedback type="invalid">
                                {validation.errors.founder}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Amount</Label>
                            <Input
                              name="Amount"
                              type="text"
                              placeholder="Insert Amount"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Amount || ""}
                              invalid={
                                validation.touched.Amount &&
                                validation.errors.Amount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.Amount &&
                            validation.errors.Amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Status</Label>
                            <Input
                              name="status"
                              type="select"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              multiple={false}
                              className="form-select"
                              value={validation.values.status || ""}
                              invalid={
                                validation.touched.status &&
                                validation.errors.status
                                  ? true
                                  : false
                              }
                            >
                              <option>Paid</option>
                              <option>Pending</option>
                            </Input>
                            {validation.touched.status &&
                            validation.errors.status ? (
                              <FormFeedback type="invalid">
                                {validation.errors.status}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Date</Label>
                            <Input
                              name="date"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.date || ""}
                              invalid={
                                validation.touched.date &&
                                validation.errors.date
                                  ? true
                                  : false
                              }
                            ></Input>
                            {validation.touched.date &&
                            validation.errors.date ? (
                              <FormFeedback type="invalid">
                                {validation.errors.date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DataCollection;
