import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  Card, 
  CardBody, 
  Col, 
  Container, 
  Row, 
  Table, 
  Collapse,
  Breadcrumb,
  BreadcrumbItem 

} from "reactstrap";
import { isEmpty, keys, map } from "lodash";
import {useParams} from 'react-router-dom';
import moment from "moment"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-sm.svg";

import { getInvoiceDetail as onGetInvoiceDetail } from "../../store/invoices/actions";

// API
import DataCollectionService from '../../api/service/DataCollectionService';

// import collectionlist from "../../common/data/collection"

//redux
import { useSelector, useDispatch } from "react-redux";
const CollectionDetails = (props: any) => {
  // const {data} = collectionlist();

  document.title = " Data Collection | Carbon Platform";

  const initialdetailsCD = {
    month: "",
    area_type: "",
    area_name: "",
    component_name: "",
    component_type: "",
    usage_value: 0,
    usage_unit: "",
    operational_cost_value: "",
    operational_cost_currency: "",
    note: ""
  };
  const initialdetailTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 8,
    location_latitude: -6.2944077,
    location_longitude: 107.1373147,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id:""
  };

  const initialdetailFile = {
    id: "",
    created_at:"",
    date_start: "",
    date_end: "",
    file_name:"",
    uploaded_by: "",
    file_link: "",
    tenant_name: "",
    tenant_id: ""
  };

  const dispatch = useDispatch();

  const { invoiceDetail } = useSelector((state: any) => ({
    invoiceDetail: state.Invoices.invoiceDetail,
  }));
  const [col5, setcol5] = useState(true);

  const t_col5 = () => {
    setcol5(!col5);
  };

  const [CollectDataDetail, setCollectDataDetail] = useState([initialdetailsCD]);
  const [DetailTenant, setDetailTenant] = useState(initialdetailTenant);
  const [DetailInfoFile, setDetailInfoFile] = useState(initialdetailFile);
  const {id} = useParams();
  console.log(id);

  useEffect(() => {

    DataCollectionService.getId(id)
    .then(response => {
      setCollectDataDetail(response.data.data.details);
      setDetailInfoFile(response.data.data.file_info);
      setDetailTenant(response.data.data.tenant);
    })
    .catch(e => {
      console.log(e);
    });
  }, [])
  // console.log(CollectDataDetail);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Data Collection" breadcrumbItem=" Detail Collection" /> */}
          <Row>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Data Collection</h4>
              <div className="page-title-right">
                <Breadcrumb className="m-0" >
                  <BreadcrumbItem>
                    <Link to={'/data-collection'}>
                    Data Collection
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    Detail
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <div className="d-flex align-items-start">
                      <div className="flex-grow-1">
                        <div className="mb-4">
                          <img src={logo} alt="" height="24" />
                          <span className="logo-txt">{DetailTenant.name}</span>
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <div className="mb-4">
                          <h4 className="float-end font-size-16">
                            <a href={DetailInfoFile.file_link} target="_blank" rel="noopener noreferrer">
                            {DetailInfoFile.file_name}
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <address>
                      <span>{DetailTenant.location_country}</span><br />
                      <span>{DetailTenant.business_sector}</span>

                    </address>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col sm="3">
                      <div>
                        <div>
                          <h5 className="font-size-15">Upload By</h5>
                          <p>{DetailInfoFile.uploaded_by}</p>
                        </div>
                      </div>
                    </Col>
                    <Col sm="3">
                      <div>
                        <div>
                          <h5 className="font-size-15">Start Date</h5>
                          <p>{moment(DetailInfoFile.date_start).format("YYYY-MM-DD")}</p>
                        </div>
                      </div>
                      
                    </Col>
                    <Col sm="3">
                      <div>
                        <div>
                          <h5 className="font-size-15">End Date</h5>
                          <p>{moment(DetailInfoFile.date_end).format("YYYY-MM-DD")}</p>
                        </div>
                      </div>
                      
                    </Col>
                  </Row>
                  <div className="d-flex gap-2 flex-wrap mb-3">
                    <div className="mt-2">
                      <Link 
                        to="#"
                        className="btn btn-success btn-sm"
                        onClick={t_col5}
                        style={{ cursor: "pointer" }}
                        >
                        View Data Collection <i className="mdi mdi-arrow-right ms-1"></i>{" "}
                      </Link>
                    </div>
                  </div>
                  <Collapse isOpen={col5}>
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>Area Type</th>
                                <th>Area Name</th>
                                <th>Component Name</th>
                                <th>Component Type</th>
                                <th>Usage Value</th>
                                <th>Usage Unit</th>
                                <th>Operational Cost Value</th>
                                <th>Operational Cost Unit</th>
                                <th>Note</th>
                              </tr>
                            </thead>
                            <tbody>
                              {map(
                                CollectDataDetail,
                                (item, key) => (
                                  <tr key={key}>
                                    <td width="150px">{moment(item.month).format("YYYY-MM-DD")}</td>
                                    <td>{item.area_type}</td>
                                    <td>{item.area_name}</td>
                                    <td>{item.component_name}</td>
                                    <td>{item.component_type}</td>
                                    <td>{item.usage_value}</td>
                                    <td>{item.usage_unit}</td>
                                    <td>{item.operational_cost_value}</td>
                                    <td>{item.operational_cost_currency}</td>
                                    <td>{item.note}</td>
                                    
                                  </tr>
                                )
                              )}
                              
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                  <hr />
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* )} */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CollectionDetails;