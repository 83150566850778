import React , { useState, useEffect }from "react";
import {
  Container, 
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown, } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import user1 from "../../../assets/images/users/avatar-1.jpg";
import AuthService from '../../../api/service/AuthService';


const Profile = () => {
  document.title = "Profile | Carbon Platform";
  const initGroup = {
    id: "",
    name: "",
    industrial_park_id: "",
    tenant_id: "",
    type: "",
    level: ""
  }
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const initUserDetail = {
    id: "",
    full_name: "",
    username: "",
    group_id: "",
    email: "",
    group_detail: initGroup,
    industrial_park_detail: initIndustrial,
    tenant_detail: initTenant
  }
  const [UserDetail, setUserDetail] = useState(initUserDetail);

  useEffect(()=>{
    AuthService.AuthMe()
    .then(res=>{
      setUserDetail(res.data.data)

    }).catch(err => console.log(err));
  },[])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User Profile" link_title="/user-profile" breadcrumbItem="User Profile" />
            <Row>
              <Col xl={12} lg={12}>
              <Card>
                  <CardBody>
                    <Row>
                      <div className="col-sm order-2 order-sm-1">
                        <div className="d-flex align-items-start mt-3 mt-sm-0">
                          <div className="flex-shrink-0">
                            <div className="avatar-xl me-3">
                              <img
                                src={user1}
                                alt=""
                                className="img-fluid rounded-circle d-block"
                              />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div>
                              <h5 className="font-size-16 mb-1">{UserDetail.full_name}</h5>
                              <p className="text-muted font-size-13">
                                {UserDetail.email}
                              </p>

                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                <div>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  Type : {UserDetail.group_detail.type}
                                </div>
                              </div>
                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                <div>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  Role : {UserDetail.group_detail.level}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                <div>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  Industrial Name : {UserDetail.industrial_park_detail.legal_name}
                                </div>
                              </div>
                              <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                <div>
                                  <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                  Tenant : {UserDetail.tenant_detail.legal_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-auto order-1 order-sm-2">
                        <div className="d-flex align-items-start justify-content-end gap-2">
                          <div>
                            {/* <button type="button" className="btn btn-soft-light">
                              <i className="me-1"></i> Message
                            </button> */}
                          </div>
                          <div>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn btn-link font-size-16 shadow-none text-muted"
                                tag="a"
                              >
                                <i className="bx bx-dots-horizontal-rounded"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <li>
                                  <DropdownItem href="#">Action</DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem href="#">Another action</DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem href="#">
                                    Something else here
                                  </DropdownItem>
                                </li>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </div>
                      </div>
                    </Row>

                    
                  </CardBody>
                </Card>
              </Col>
             
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
