import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = (size:number) => {
  return http.get(`/carbon/v1/cost-operation?size=${size}`,{ headers: authHeader() });
};
const getId = (id:any) => {
  return http.get(`/carbon/v1/cost-operation/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/cost-operation", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/cost-operation/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/cost-operation/${id}`, { headers: authHeader() });
};

const CostService = {
  getAll,
  create,
  remove,
  getId,
  update
};

export default CostService;
