import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
// import swal from 'sweetalert';
import Swal from 'sweetalert2';

import { 
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Input,
  Label,
  Table,
  Toast,
  ToastHeader,
  ToastBody,
  Button } from "reactstrap";
  import ReactPaginate from "react-paginate";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import AssetsListService from '../../../api/service/AssetsListService';
import EmbeddedLinkTenant from '../../../api/service/EmbeddedLinkTenant';
import TenantService from '../../../api/service/TenantService';

import { use } from "echarts";
import moment from "moment";


const EmbededList = () => {
  document.title = "Assets List | Carbon Platform";

  
  const initCreate = {
    name:"",
    link:"",
    page_destination :"",
    tenant_id:"",
  }
  const initEmbeddedLink = {
    id: "",
    name: "",
    link: "",
    page_destination: "",
    updated_by: "",
    updated_at: "",
    tenant_id: "",
    tenant_name: ""
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [ListPageDestination, setListPageDestination] = useState([]);
  const [dataEmbedlink, setdataEmbedlink] = useState([]);
  const [Tenant, setTenant] = useState([initTenant]);
  const listDestination = [
    "Custom",
    "Emission Type",
    "Monthly",
    "Summary"
  ]

  const [filter_area , setFilter] = useState("")
  const [filter_type , setFiltertype] = useState("")
  const [select_tenant , setSelect_tenant] = useState("")
  const [search_name , setSearchName] = useState("")
  var tenant_user = localStorage.getItem('tenant_id') || "";

  const [body, setbody] = useState(initCreate);
  const [currentData, setCurrentData] = useState([initEmbeddedLink]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);

  const [delete_status, setdelete_status] = useState(false);
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbody({ ...body, [name]: value });
  };
  const save = ()=> {
    var data = {
      name : body.name,
      link : body.link,
      page_destination: body.page_destination,
      tenant_id: body.tenant_id,
    }
    // // console.log(data)
    EmbeddedLinkTenant.create(data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setbody(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this Link!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        EmbeddedLinkTenant.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your Link has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
  };
  useEffect(() => {
    // console.log(tenant_user)
    EmbeddedLinkTenant.getPageDestination(tenant_user)
    .then(res => {
      setListPageDestination(res.data.data.destination);
      
    }).catch(err => console.log(err));
  }, [modal_xlarge])

  useEffect(()=>{
    
    EmbeddedLinkTenant.getAll(select_tenant,filter_type,search_name)
    .then(res => {
      try {
        setdataEmbedlink(res.data.data)
        
      } catch (error) {
        setdataEmbedlink([]);
      }
      // console.log(dataAssetList)
      
    }).catch(err => console.log(err));
    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));
  },[search_name, filter_area,filter_type, modal_xlarge, delete_status, select_tenant])
  // setdataAssetList([]);
  // console.log(dataAssetList.length)

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  // function tog_edit() {
  //   setmodal_edit(!modal_xlarge);
  //   removeBodyCss();
  // }
  
  useEffect(() => {
    const endOffset = dataOffset + 10;
    setCurrentData(dataEmbedlink.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(dataEmbedlink.length / 10));
  }, [dataEmbedlink, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % dataEmbedlink.length;
    setDataOffset(newOffset);
  }; 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Embeded Link" link_title="embeded-link" breadcrumbItem="Embeded Link" />
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add Entry
                </button>
              </div>
            </Col>
            <div>
            <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                tog_xlarge();
                }}
                scrollable={true}
            >
                <div className="modal-header">
                <h5
                    className="modal-title mt-0"
                    id="myExtraLargeModalLabel"
                >
                    Add Embedded Link
                </h5>
                <button
                    onClick={() => {
                    setmodal_xlarge(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                {/* Modal Content */}
                <div className="modal-body">
                <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Name</Label>
                    <Input 
                    className="form-control"
                    placeholder="Name"
                    type="text" 
                    id="name"
                    name="name"
                    value={body.name}
                    onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Page Destination</Label>
                    <select 
                    value={body.page_destination} 
                    onChange={handleInputChange}
                    id="page_destination"
                    name="page_destination"
                    className="form-select">
                        <option value="">Select Page Destination</option>
                        {
                            ListPageDestination?.map((raw, index)=>(
                            <option 
                                key={index} 
                                value={raw}
                                // onChange={Handlefilter}
                                >{raw}
                            </option>

                            ))
                        }
                    </select>
                </div>
                <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Link</Label>
                    <Input 
                    className="form-control"
                    placeholder="https://..."
                    type="text" 
                    id="link"
                    name="link"
                    value={body.link}
                    onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>

                    <select 
                    value={body.tenant_id} 
                    onChange={handleInputChange}
                    id="tenant_id"
                    name="tenant_id"
                    className="form-select">
                        <option value="">Select Tenant</option>
                        {
                            Tenant?.map((raw, index)=>(
                            <option 
                                key={raw.id} 
                                value={raw.id}
                                >{raw.name}
                            </option>

                            ))
                        }
                    </select>
                </div>

                <div className="modal-footer">
                    <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setmodal_xlarge(false)}
                    >
                    Cancel
                    </button>
                    {
                    <button 
                        type="button"
                        onClick={() => {
                        save();
                        }}
                        className="btn btn-success">
                        Save
                    </button>
                    }
                    
                </div>
                </div>
            </Modal>
            </div>
          </Row>
          <br />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                    </Col>
                    <Col md="8">
                    <Row>
                      <Col sm='6'>
                        <div className="mb-0">
                          <Input 
                            className="form-control" 
                            type="text" 
                            id="name"
                            name="name"
                            placeholder="Search Name"
                            value={search_name}
                            onChange={({ currentTarget }) => setSearchName(currentTarget.value,)}
                            
                            />
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="mb-0">
                          <select 
                            value={filter_area} 
                            // onChange={Handlefilter}
                            onChange={({ currentTarget }) => setSelect_tenant(currentTarget.value,)}
                            className="form-select">
                              <option value="">Tenant</option>
                                {
                                  Tenant?.map((raw, index)=>(
                                    <option 
                                      key={raw.id} 
                                      value={raw.id}
                                      // onChange={Handlefilter}
                                      >{raw.name}
                                    </option>

                                  ))
                                }
                          </select>
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="mb-0">
                          <select 
                              // style={{width:"60%", display:"inline"}}
                              value={filter_type} 
                              // onChange={Handlefilter}
                              onChange={({ currentTarget }) => setFiltertype(currentTarget.value,)}
                              className="form-select">
                                <option value="">Page Destination</option>
                                  {
                                    listDestination.map((raw, index)=>(
                                      <option 
                                        key={index} 
                                        value={raw}
                                        // onChange={Handlefilter}
                                        >{raw}
                                      </option>

                                    ))
                                  }
                            </select>
                          
                        </div>
                      </Col>
                      </Row>
                      
                      
                      
                    </Col>
                  </Row>
                  <br />
                  <Row>
                  <div className="table-responsive">
                    <Table className="table-bordered table mb-0">
                      <thead className="bg-success text-white">
                        <tr>
                          <th>Name</th>
                          <th>Tenant</th>
                          <th>Page Destination</th>
                          <th>Embedded Link</th>
                          <th>Update By</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentData.length >0 ? (
                            currentData.map((raw,index)=>(
                              // dataAssetList.map((raw,index)=>(
                              <tr key={raw.id}>
                                <td>{raw.name}</td>
                                <td>{raw.tenant_name}</td>
                                <td>{raw.page_destination}</td>
                                <td>{raw.link}</td>
                                <td>{raw.updated_by}</td>
                                <td>
                                <div className="d-flex gap-3">
                                  
                                  {/* <Link className="text-primary" to={"/data-collection/detail/"+raw.id}>
                                    <i className="bx bx-download label-icon"></i>
                                  </Link> */}
                                  <Link className="text-success" to={"/embeded-link/edit/"+raw.id}
                                  >
                                    <i
                                      className=" bx bx-pencil font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </Link>
                                  <a className="text-danger" href="#"
                                    onClick={() => {handleDelete(raw.id)}} 
                                  >
                                    <i
                                      className=" bx bx-trash-alt font-size-18"
                                      id="edittooltip"
                                    ></i>
                                  </a>
                                  
                                </div>
  
                                </td>
                              </tr>
                            ))
                          ) :
                          (
                            <tr>
                              <td colSpan={8} className="text-center text-bold">No Data Available</td>
                            </tr>
                          )
                          
                        }
                        
                        
                      </tbody>
                    </Table>
                    <hr />
                  </div>
                  
                  <div className="text-sm-end">
                    <div className="justify-content-end pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={10}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                  
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmbededList;
