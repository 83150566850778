import ReactApexChart from "react-apexcharts";
import React from "react";
import moment from "moment";
import { match } from "assert";


const ColumnWithMarker = (data:any) => {

  var data_chart = []
  var category = []
  for (let index = 0; index < data.data.length; index++) {
    data_chart.push({
      x: moment(data.data[index]["month"]).format("MMM YYYY"),
      y: Math.round(data.data[index]["actual"]),
      goals:[
        {
          name: 'Threshold',
          value: Math.round(data.data[index]["upper_threshold"]),
          strokeHeight:5,
          strokeWidth:15,
          strokeColor: '#A73651'
        }
      ]
    })
    // data_chart.push(data.data[index]["value"])
    // category.push(moment(data.data[index]["datetime"]).format("MMM YYYY"))
  }
  const series = [
    {
        name: 'Actual',
        data: data_chart
      },
  ];
  const options: Object = {
    chart: {
        height: 350,
        type: 'bar'
      },
      yaxis: {
        title: {
          text: "Ton CO2e",
        },
        labels: {
          formatter: (val: number) => {
            if (val>0) {
              return (val/1000).toFixed(1) + "K"
              
            }
            else {
              return val;
            }
            // return (val/1000).toFixed(1) + "K"

            // var count = Math.round((val) / 1000)
            // return count + 'K'
            // if (val>1000) {
            //   var count = Math.round((val) / 1000)
            //   return count + 'K'
            // }
            // else{
            //   return (val/1000).toFixed(1) + "K"
  
            // }
          }
        }
      },
      dataLabels: {
        enabled: false,
        formatter: (val: number) => {
          if (val>1000) {
            var count = Math.round((val) / 1000)
            return count + 'K'
          }
          else{
            return Math.round(val) + " Ton CO2e"
  
          }
        }
        
      },
      tooltip: {
        y: {
          formatter: function (val:number) {
            if (val>1000) {
              var count = Math.round((val) / 1000)
              return count + 'K'
            }
            else{
              return Math.round(val) + " Ton CO2e"
            }
          }
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '10%'
        }
      },
      colors: ['#36A7A7'],
      // dataLabels: {
      //   enabled: false
      // },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Actual', 'Threshold'],
        markers: {
          fillColors: ['#36A7A7', '#A73651']
        }
      }
  };
  return (
    <ReactApexChart options={options} series={series} type="bar" height={360} className="apex-charts" />
  );
};
export default ColumnWithMarker;
