import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = () => {
  return http.get("/carbon/v1/tenant", { headers: authHeader() });
};
const getId = (id:any) => {
  return http.get(`/carbon/v1/tenant/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/tenant", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/tenant/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/tenant/${id}`, { headers: authHeader() });
};

const DataTenantService = {
  getAll,
  getId,
  create,
  update,
  remove
};

export default DataTenantService;
