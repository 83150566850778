import React, {useEffect, useMemo, useState} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  Input,
  Label,
 } from "reactstrap";
 import ReactPaginate from "react-paginate"; 
 import { Link, useNavigate } from "react-router-dom";
 import Swal from 'sweetalert2';
 import IndustrialService from '../../api/service/IndustrialService';
 import TenantService from '../../api/service/TenantService';
 import UserService from "../../api/service/UserService";
 import GroupService from "../../api/service/GroupService";
 
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";
import { setgroups } from "process";
import Icon from "@ailibs/feather-react-ts";


const PageStarter = () => {
  document.title = "User Management | Carbon Platform";
  const initGroup = {
    id: "",
    name: "",
    industrial_park_id: "",
    tenant_id: "",
    type: "",
    level: ""
  }
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const initUserDetail = {
    id: "",
    full_name: "",
    username: "",
    group_id: "",
    email: "",
    group_detail: initGroup,
    industrial_park_detail: initIndustrial,
    tenant_detail: initTenant
  }
  const initCreate = {
    full_name:"",
    username:"",
    password_ori:"",
    retype_password:"",
    group_id:"",
    email:"",
    industrial_id : "",
    tenant_id : "",
    type :""
  }
  // Data
  const [UserList, setUserList] = useState([initUserDetail]);
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [Tenant, setTenant] = useState([initTenant]);
  const [Group, setGroup] = useState([initGroup]);
  const [FilterTenant, setFilterTenant] = useState([initTenant]);
  const [select_industrial , setSelectIndustrial] = useState("")
  const [select_tenant , setSelect_tenant] = useState("")
  const [search_name , setSearchName] = useState("")
  const type = [
    {
      alias_name:"industrial_park",
      name: "Industrial Park"
    }, 
    // {
    //   alias_name:"Tenant",
    //   name: "Tenant"
    // }
  ];


  const [delete_status, setdelete_status] = useState(false);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  // Pagination
  const [currentData, setCurrentData] = useState([initUserDetail]);
  const [pageCount, setPageCount] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const [status_role, setstatus_role] = useState(false)
  const [status_password, setstatus_password] = useState(false)
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowretype, setPasswordShowretype] = useState(false);
  const [status_save, setstatus_save] = useState(false);


  useEffect(()=>{

    UserService.getAll(search_name, select_industrial, select_tenant)
    .then(res=>{
      setUserList(res.data.data)
    }).catch(err => console.log(err));

    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));

    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));


  },[ modal_xlarge, delete_status, search_name, select_industrial, select_tenant])


  // Filter Tenant
  var tableData1 = Tenant;
  useEffect(() => {
    if (bodyAsset.industrial_id) {
      tableData1 = Tenant.filter((data)=>
        data.industrial_park_id==bodyAsset.industrial_id
      )
      setFilterTenant(tableData1)
    }
    else{
      setFilterTenant(tableData1)
    }
  }, [bodyAsset.industrial_id, tableData1])

  // Checking Password
  useEffect(() => { 
  if (bodyAsset.password_ori!=bodyAsset.retype_password) {
    setstatus_password(false)
  }else{
    setstatus_password(true)

  }

  },[bodyAsset.retype_password])
  // Get Group

  useEffect(() => { 
    if (bodyAsset.industrial_id!="" && bodyAsset.tenant_id!="" && bodyAsset.type!="") {
      setstatus_role(true);
    }else{
      setstatus_role(false);

    }
    if (status_role==true && bodyAsset.email!="" && bodyAsset.username!="" && bodyAsset.password_ori!="" && bodyAsset.retype_password!="" && status_password==true && bodyAsset.group_id!="" ) {
      setstatus_save(true)
    }else {
      setstatus_save(false)
    }
    GroupService.getAll(bodyAsset.type, bodyAsset.industrial_id, bodyAsset.tenant_id)
    .then(res=>{
      setGroup(res.data.data)
    }).catch(err => console.log(err));

  }, [bodyAsset.type, bodyAsset.industrial_id, bodyAsset.tenant_id, bodyAsset.email, bodyAsset.full_name, bodyAsset.password_ori, bodyAsset.retype_password, bodyAsset.group_id, status_password, status_save])

  //Paginantion
  useEffect(() => {
    const endOffset = dataOffset + 10;
    setCurrentData(UserList.slice(dataOffset,endOffset));
    setPageCount(Math.ceil(UserList.length / 10));
  }, [UserList, dataOffset]);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * 10) % UserList.length;
    setDataOffset(newOffset);
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  function toTitleCase(str:any) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map((word:any) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  
    return titleCase;
  }
  const save = ()=> {
    var data = {
      full_name: bodyAsset.full_name,
      username: bodyAsset.username,
      password: bodyAsset.password_ori,
      group_id:bodyAsset.group_id,
      email:bodyAsset.email
    }
    console.log(data)
    UserService.create(data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your data saved!',
        showConfirmButton: false,
        timer: 1500
      })
      setbodyAsset(initCreate);
      setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleDelete = (id:string) => {
    Swal.fire({
      title: 'Delete Confirmation',
      text: "Are you sure delete this User!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.remove(id)
        .then(response => {
          Swal.fire(
            'Deleted!',
            'Your User has been deleted!',
            'success'
          )
          setdelete_status(true)
        })
        .catch(e => {
          console.log(e);
        });
        
      }
    })
    
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Pages" breadcrumbItem="Starter Page" /> */}
          <Breadcrumbs title="User Management" link_title="/user-management" breadcrumbItem="User List" />
          <Row>
            <Col>
              <div className="text-sm-end">
                <button
                  type="button"
                  className="btn btn-light waves-effect waves-light  float-end"
                  onClick={() => {
                    tog_xlarge();
                  }}
                  data-toggle="modal"
                  data-target=".bs-example-modal-xl"
                >
                  <i className="bx bx-plus me-1"></i> Add User
                </button>
              </div>
            </Col>
            <div>
              <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge();
                }}
                scrollable={true}
              >
                <div className="modal-header">
                  <h5
                    className="modal-title mt-0"
                    id="myExtraLargeModalLabel"
                  >
                    New User
                  </h5>
                  <button
                    onClick={() => {
                      setmodal_xlarge(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {/* Modal Content */}
                <div className="modal-body">
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Full Name</Label>
                    <Input 
                      className="form-control" 
                      type="text" 
                      id="full_name"
                      name="full_name"
                      value={bodyAsset.full_name}
                      onChange={handleInputChange}
                      />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Username</Label>
                    <Input 
                      className="form-control" 
                      type="text" 
                      id="username"
                      name="username"
                      value={bodyAsset.username}
                      onChange={handleInputChange}
                      />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Email</Label>
                    <Input 
                      className="form-control" 
                      type="email" 
                      id="email"
                      name="email"
                      value={bodyAsset.email}
                      onChange={handleInputChange}
                      />
                  </div>
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Password</label>
                            </div>
                          </div>

                          <div className="input-group auth-pass-inputgroup">
                            <input
                              type={passwordShow ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter password"
                              aria-label="Password"
                              aria-describedby="password-addon"
                              value={bodyAsset.password_ori}
                              onChange={handleInputChange}
                              id="password_ori"
                              name="password_ori"
                            />
                            <button
                              onClick={() => setPasswordShow(!passwordShow)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                      <Col>
                      <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Retype Password</label>
                            </div>
                          </div>

                          <div className="input-group auth-pass-inputgroup">
                            <input
                              type={passwordShowretype ? "text" : "password"}
                              className="form-control"
                              placeholder="Retype password"
                              aria-label="Password"
                              aria-describedby="password-addon"
                              value={bodyAsset.retype_password}
                              onChange={handleInputChange}
                              id="retype_password"
                              name="retype_password"
                            />
                            <button
                              onClick={() => setPasswordShowretype(!passwordShowretype)}
                              className="btn btn-light shadow-none ms-0"
                              type="button"
                              id="password-addon"
                            >
                              <i className="mdi mdi-eye-outline"></i>
                            </button>
                          </div>
                          {status_password ? "": <p className='text-danger'>Password Not Match!!</p>
                          }
                          {/* {status_password ? "": <div className="invalid-feedback">Password Not Match</div>} */}
                        </div>
                      </Col>
                    </Row>
                    
                  </div>
                  
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
                    <select 
                      value={bodyAsset.industrial_id} 
                      onChange={handleInputChange}
                      placeholder="Industrial Park"
                      id="industrial_id"
                      name="industrial_id"
                      className="form-select">
                          <option value="">Select Industrial Park</option>
                          {
                            Industrial?.map((raw, index)=>(
                              <option 
                                key={index} 
                                value={raw.id}
                                >{raw.legal_name}
                              </option>

                            ))
                          }
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>
                    <select 
                      value={bodyAsset.tenant_id} 
                      onChange={handleInputChange}
                      placeholder="Tenant"
                      id="tenant_id"
                      name="tenant_id"
                      className="form-select">
                          <option value="">Select Tenant</option>
                          {
                            FilterTenant?.map((raw, index)=>(
                              <option 
                                key={index} 
                                value={raw.id}
                                >{raw.legal_name}
                              </option>

                            ))
                          }
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Type</Label>
                    <select 
                      value={bodyAsset.type} 
                      onChange={handleInputChange}
                      placeholder="type"
                      id="type"
                      name="type"
                      className="form-select">
                          <option value="">Select Type</option>
                          {
                            type?.map((raw, index)=>(
                              <option 
                                key={index} 
                                value={raw.alias_name}
                                >{raw.name}
                              </option>

                            ))
                          }
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="example-text-input" className="form-Label">Role</Label>
                    <select
                      value={bodyAsset.group_id} 
                      onChange={handleInputChange}
                      placeholder="Group"
                      id="group_id"
                      name="group_id"
                      className="form-select"
                      disabled={status_role ? false : true}
                      >

                          <option value="">Select Role</option>
                          {
                            Group?.map((raw, index)=>(
                              <option 
                                key={index} 
                                value={raw.id}
                                >{raw.level}
                              </option>

                            ))
                          }
                    </select>
                  </div>
                  
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => setmodal_xlarge(false)}
                    >
                      Cancel
                    </button>
                    {
                      <button 
                        type="button"
                        disabled={status_save ? false : true}
                        onClick={() => {
                          save();
                        }}
                        className="btn btn-success">
                        Save
                      </button>
                      }
                    
                  </div>
                </div>
              </Modal>
              </div>
          </Row>
          <br />
          <Row>
            <Col xl={12}>
              <Card>
                <div className="table-responsive">
                  <CardBody>
                  <Row>
                      <Col></Col>
                      <Col md={8}>
                        <Row>
                          <Col md='4'>
                            <div className="mb-0">
                              <Input 
                                className="form-control" 
                                type="text" 
                                id="name"
                                name="name"
                                placeholder="Search Name"
                                value={search_name}
                                onChange={({ currentTarget }) => setSearchName(currentTarget.value,)}
                                
                                />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-0">
                              <select 
                                value={select_industrial} 
                                // onChange={Handlefilter}
                                onChange={({ currentTarget }) => setSelectIndustrial(currentTarget.value,)}
                                className="form-select">
                                  <option value="">Industrial Park</option>
                                    {
                                      Industrial?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw.id}
                                          >{raw.legal_name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-0">
                              <select 
                                value={select_tenant} 
                                // onChange={Handlefilter}
                                onChange={({ currentTarget }) => setSelect_tenant(currentTarget.value,)}
                                className="form-select">
                                  <option value="">Tenant</option>
                                    {
                                      Tenant?.map((raw, index)=>(
                                        <option 
                                          key={index} 
                                          value={raw.id}
                                          >{raw.legal_name}
                                        </option>

                                      ))
                                    }
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {/* <Col xl={2}> */}
                        {/* <Button className="float-end btn btn-success btn-sm">Tambah</Button> */}
                        
                        
                      {/* </Col> */}
                    </Row>
                    <br />
                    <div className="table-responsive">
                      <Table className="table-bordered table mb-0">
                        <thead className="bg-success text-white">
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Tenant </th>
                            <th>Role</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            currentData.length >0 ? (
                              currentData.map((raw,index)=>(
                                <tr key={raw.id}>
                                  <td>{toTitleCase(raw.full_name)}</td>
                                  <td>{raw.email}</td>
                                  <td>{raw.industrial_park_detail.legal_name}</td>
                                  <td>{raw.tenant_detail.legal_name}</td>
                                  <td>
                                    <div className={"badge badge-soft-" + "success" + " font-size-12"}>
                                      {toTitleCase(raw.group_detail.level)}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <Link className="text-success" to={"/user-management/edit/"+raw.id}>
                                        <i
                                          className=" bx bx-pencil font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </Link>
                                      <a className="text-danger" href="#"
                                        onClick={() => {handleDelete(raw.id)}} 
                                      >
                                        <i
                                          className=" bx bx-trash-alt font-size-18"
                                          id="edittooltip"
                                        ></i>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ):
                            (
                              <tr>
                                <td colSpan={7}>No Data Emission Factor</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                    <div className="text-sm-end">
                      <div className="justify-content-end pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={10}
                          pageCount={pageCount}
                          previousLabel="< previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </CardBody>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageStarter;
