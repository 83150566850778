import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  
 } from "reactstrap";
import Select from "react-select";

// Service
import AnalyticsService from '../../api/service/AnalyticsService';

// Date
import { DateRangePicker, Stack } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';


import Flatpickr from "react-flatpickr";


import { WidgetsData } from "../../common/data/dashboard";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import TotalEmision from "../AllCharts/apex/analytics/TotalEmision"; 
import TotalProductivity from "../AllCharts/apex/analytics/TotalProductivity"; 
import TotalEmissionUnit from "../AllCharts/apex/analytics/TotalEmissionUnit"; 

import { embedDashboard } from "@superset-ui/embedded-sdk";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import icon_total_emission from "../../assets/images/brands/icon-card/icon-total-emission.svg";
import icon_carbon_tax from "../../assets/images/brands/icon-card/icon-carbon-tax.svg";
import icon_total_operational_cost from "../../assets/images/brands/icon-card/icon-total-operational-cost.svg";
import icon_company_assets from "../../assets/images/brands/icon-card/icon-company-assets.svg";
import icon_electricty_usage from "../../assets/images/brands/icon-card/icon-electricty-usage.svg";
import TotalEmisionUnit from "../AllCharts/apex/analytics/TotalEmissionUnit";
import AssetsListService from '../../api/service/AssetsListService';

import Helper from "../../helpers/service/Helper";

import moment from "moment";

const Overview = () => {
  document.title = "Overview | Carbon Platform";
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initOverallEmission = {
    value: 0,
    unit: "",
    condition_last_month: 0,
    status: ""
  }
  const initMonthlyEmission = {
    datetime: "",
    value: 0  
  }

  const initAreaEmission = {
    area_name:"",
    monthly_emissions:[
      {
        datetime: "",
        value: ""
      }
    ]
  }

  const [TotalEmisionCard, setTotalEmissionCard] = useState(initOverallEmission);
  const [OperationalCost, setOperationalCost] = useState(initOverallEmission);
  const [CompanyAsset, setCompanyAsset] = useState(initOverallEmission);
  const [ElextricUsage, setElextricUsage] = useState(initOverallEmission);
  const [CarbonTax, setCarbonTax] = useState(initOverallEmission);
  const [ChartTotalEmission, setChartTotalEmission] = useState([initMonthlyEmission]);
  const [ChartTotalEmissionUnit, setChartTotalEmissionUnit] = useState([initAreaEmission]);
  const [area, setArea] = useState([initArea]);
  const [filter_area , setFilter] = useState("")
  
  // const [FilterDate, setFilterDate] = useState("");
  const [FilterDate, setFilterDate] = useState([new Date(), new Date()]);
  var start = "";
  var end = "";
  if(FilterDate!=null){
    start = moment(FilterDate[0]).format("YYYY-MM-DD");
    end = moment(FilterDate[1]).format("YYYY-MM-DD")
    if (start==end) {
      start=""
      end = ""
    }
  }
  else {
    start=""
    end = ""
  }
  // var start = moment(FilterDate[0]).format("YYYY-MM-DD");
  // var end = moment(FilterDate[1]).format("YYYY-MM-DD")
  // if (start==end) {
  //   start=""
  //   end = ""
  // }
  useEffect(() => {
    // AnalyticsService.getOverview()
    AnalyticsService.getOverviewFilter(filter_area, start,end)
    .then(response => {
      setTotalEmissionCard(response.data.data.summary.total_emission);
      setOperationalCost(response.data.data.summary.total_operational_cost);
      setCompanyAsset(response.data.data.summary.total_asset);
      setElextricUsage(response.data.data.summary.total_electricity_usage);
      setCarbonTax(response.data.data.summary.carbon_tax);
      setChartTotalEmission(response.data.data.monthly_chart);
      setChartTotalEmissionUnit(response.data.data.area_monthly_chart);
    })
    .catch(e => {
        console.log(e);
      });

  }, [FilterDate, filter_area])

  useEffect(()=>{
    AssetsListService.getArea()
    .then(res => {
      setArea(res.data.data);
    }).catch(err => console.log(err));
  },[])
  function handleSelectGroup(selectedGroup: any) {
    setFilter(selectedGroup.value);
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Analytics" breadcrumbItem="Analytics" />
            <Row>
              <Col sm={8}>
                <div className="btn-group">
                  <Link to="/analytics-overview" className="btn btn-success">
                      Overview
                  </Link>
                  <Link to="/analytics-breakdown" className="btn btn-outline-success">
                    Breakdown
                  </Link>
                  <Link to="/analytics-custom" className="btn btn-outline-success">
                    Custom
                  </Link>
                </div>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm="6">
                    <div className="text-sm-end">
                    {/* <Select
                      defaultValue={filter_area}
                      onChange={(e : any) => {
                        handleSelectGroup(e);
                      }}
                      options={area}
                      classNamePrefix="select2-selection"
                    /> */}
                      <select 
                          value={filter_area} 
                          onChange={({ currentTarget }) => setFilter(currentTarget.value,)}

                          className="form-select">
                            <option value="">All Area</option>
                              {
                                area?.map((raw, index)=>(
                                  <option 
                                    key={raw.id} 
                                    value={raw.id}
                                    // onChange={Handlefilter}
                                    >{raw.name}
                                  </option>

                                ))
                              }
                      </select>
                    </div>
                  </Col>
                  <Col Col sm="6">
                    <div className="text-sm-end">
                  
                      <DateRangePicker
                        onChange={(e:any) => setFilterDate(e)}
                        placement="bottomEnd"
                        placeholder="Periode"
                        style={{ width: 300 }}
                      />
                      <br />
                      <br />
                  </div>
                  </Col>
                </Row>
                
                

              </Col>

            </Row>
            <Row>
              <Col xs={3}>
                <Col xl={12} md={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={10}>
                          <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            Total Emission
                          </span>
                          <div className="d-flex">
                            <div className="flex-grow-1 overflow-hidden me-4">
                              <h4 className="font-size-25 mb-1">
                                <span className="counter-value">
                                {Math.round(TotalEmisionCard.value).toLocaleString('en-US')}
                                </span>
                              </h4>
                              <p className="text-truncate text-muted font-size-13">
                                {TotalEmisionCard.unit}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <img className="card-img img-fluid mb-5" src={icon_total_emission} alt="" />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-"+TotalEmisionCard.status +
                            " text-"+TotalEmisionCard.status
                          }
                        >
                          {TotalEmisionCard.condition_last_month} %
                        </span>
                        <span className="ms-1 text-muted font-size-10">
                        Since last month
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12} md={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={10}>
                          <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            Total Operational Cost
                          </span>
                          <div className="d-flex">
                            <div className="flex-grow-1 overflow-hidden me-4">
                              <h4 className="font-size-25 mb-1">
                                <span className="counter-value">
                                  {/* {OperationalCost.value.toLocaleString('en-US')}
                                  <br /> */}
                                  {Helper.formatNumber(OperationalCost.value)}

                                </span>
                              </h4>
                              <p className="text-truncate text-muted font-size-13">
                              {OperationalCost.unit}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <img className="card-img img-fluid mb-5" src={icon_total_operational_cost} alt="" />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-"+OperationalCost.status +
                            " text-"+OperationalCost.status
                          }
                        >
                         {OperationalCost.condition_last_month} %
                        </span>
                        <span className="ms-1 text-muted font-size-10">
                        Since last month
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12} md={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={10}>
                          <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            Company Assets
                          </span>
                          <div className="d-flex">
                            <div className="flex-grow-1 overflow-hidden me-4">
                              <h4 className="font-size-25 mb-1">
                                <span className="counter-value">
                                  {CompanyAsset.value.toLocaleString('en-US')}
                                </span>
                              </h4>
                              <p className="text-truncate text-muted font-size-13">
                              {CompanyAsset.unit}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <img className="card-img img-fluid mb-5" src={icon_company_assets} alt="" />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-"+CompanyAsset.status +
                            " text-"+CompanyAsset.status
                          }
                        >
                          {CompanyAsset.condition_last_month}%
                        </span>
                        <span className="ms-1 text-muted font-size-10">
                        Since last month
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12} md={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={10}>
                          <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            Electricity Usage
                          </span>
                          <div className="d-flex">
                            <div className="flex-grow-1 overflow-hidden me-4">
                              <h4 className="font-size-25 mb-1">
                                <span className="counter-value">
                                  {ElextricUsage.value.toLocaleString('en-US')}
                                </span>
                              </h4>
                              <p className="text-truncate text-muted font-size-13">
                                {ElextricUsage.unit}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <img className="card-img img-fluid mb-5" src={icon_electricty_usage} alt="" />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-"+ElextricUsage.status +
                            " text-"+ElextricUsage.status
                          }
                          >
                          {ElextricUsage.condition_last_month}%
                        </span>
                        <span className="ms-1 text-muted font-size-10">
                        Since last month
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12} md={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={10}>
                          <span className="text-muted mb-3 lh-1 d-block text-truncate">
                            Carbon Tax
                          </span>
                          <div className="d-flex">
                            <div className="flex-grow-1 overflow-hidden me-4">
                              <h4 className="font-size-25 mb-1">
                                <span className="counter-value">
                                  {CarbonTax.value.toLocaleString('en-US')}
                                  {/* <CountUp
                                    start={0}
                                    end={CarbonTax.value}
                                    duration={3}
                                  /> */}
                                </span>
                              </h4>
                              <p className="text-truncate text-muted font-size-13">
                                {CarbonTax.unit}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col xs={2}>
                          <img className="card-img img-fluid mb-5" src={icon_carbon_tax} alt="" />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span
                          className={
                            "badge badge-soft-"+CarbonTax.status +
                            " text-"+CarbonTax.status
                          }
                        >
                          {CarbonTax.condition_last_month}%
                        </span>
                        <span className="ms-1 text-muted font-size-10">
                        Since last month
                        </span>
                      </div>
                      
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              <Col xs={9}>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Total Emission</h4>
                    </CardHeader>
                    <CardBody>
                      <TotalEmision data={ChartTotalEmission}/>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Total Productivity</h4>
                    </CardHeader>
                    <CardBody>
                      <TotalProductivity />
                    </CardBody>
                  </Card>
                </Col> */}
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Total Emission Per Area</h4>
                    </CardHeader>
                    <CardBody>
                      <TotalEmisionUnit data={ChartTotalEmissionUnit}/>
                    </CardBody>
                  </Card>
                </Col>
              </Col>

              
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Overview;
