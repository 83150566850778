import http from "../default";
import authHeader from "./AuthHeaderService";

const getAll = (size:number) => {
  return http.get(`/carbon/v1/asset-usage?size=${size}`,{ headers: authHeader() });
};

const getId = (id:any) => {
  return http.get(`/carbon/v1/asset-usage/${id}`, { headers: authHeader() });
};

const create = (data:any) => {
  return http.post("/carbon/v1/asset-usage", data, { headers: authHeader() });
};

const update = (id:any, data:any) => {
  return http.put(`/carbon/v1/asset-usage/${id}`, data, { headers: authHeader() });
};

const remove = (id:any) => {
  return http.delete(`/carbon/v1/asset-usage/${id}`, { headers: authHeader() });
};

const AssetUsageService = {
  getAll,
  create,
  remove,
  getId,
  update
};

export default AssetUsageService;
