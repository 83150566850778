import React from "react";
import { 
  Container,
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
 } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";
import { Link } from "react-router-dom";

// Asset
import img3 from "../../assets/images/small/img-3.jpg";
import icon_mr from "../../assets/images/brands/bg-icon-mr.svg";
import icon_cdp from "../../assets/images/brands/bg-con-cr.svg";
import icon_cost_report from "../../assets/images/brands/bg-icon-cost-report.svg";

const Report = () => {
  document.title = "Report | Carbon Platform";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Report" link_title="assets-list" breadcrumbItem="Report" />
          <Row>
            {/* <h4>Report List</h4> */}
            <Col lg={4}>
              <Link to="/report/monthly">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Monthly Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_mr} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
            <Col lg={4}>
              <Link to="/report/cdp">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Summary Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_cdp} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
            <Col lg={4}>
              <Link to="/report/cost">
                <Card>
                  <Row className="g-0 align-items-center">
                    <Col md={9}>
                      <CardBody>
                        <h5 className="card-title">Emission Type Report</h5>
                        
                        <p className="card-text">
                          <small className="text-muted">
                          {/* September 2022 */}
                          </small>
                        </p>
                      </CardBody>
                    </Col>
                    <Col md={3}>
                      <img className="card-img img-fluid" src={icon_cost_report} alt="" />
                    </Col>
                  </Row>
                </Card>
              </Link>
              
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <iframe
                width="100%"
                height="1000"
                seamless
                frameBorder="0"
                // scrolling="no"
                scrolling="auto"
                src="https://superset.musagreen.com/superset/explore/p/j9RlQVN2GLP/?standalone=1&height=400"
                // src="http://localhost:8088/superset/dashboard/5/?standalone=1&show_filters=0"
              >
              </iframe>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Report;
