import React, { useState, useRef } from "react";
import Swal from 'sweetalert2';

import {  
    Container,
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Input, 
    Label,
    Form,
    Button,
    Breadcrumb,
    BreadcrumbItem  
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link, useNavigate  } from "react-router-dom";
// import { useHistory } from "react-router-dom";

// API
import DataCollectionService from '../../api/service/DataCollectionService';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import { Redirect } from "react-router";

const AddCollection = () => {
  document.title = "Data Collection | Carbon Platform";
  const user_token = localStorage.getItem('user');

  const defaultBody = {
      calibration_date: "",
      expiration_date: "",
      company_uuid: "",
      result: "",
    }
  // const axios = axiosOriginal.create({ adapter })
  const initError = {
      error:"",
      message:"",
      status:0
  }
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [date_start, setDateStart] = useState("");
  const [date_end, setDateEnd] = useState("");
  const [filenya] =  [useRef<HTMLInputElement>(null)];
  const [isSuccess, setisSuccess] = useState(false);
  const [iserror, setError] = useState(initError);

  const navigate = useNavigate();
  // let history = useHistory();
  // this.handleInputChange = this.handleInputChange.bind
  console.log(date_end);
  


  function handleAcceptedFiles(files: any) {
  files.map((file: any) =>
      Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      })
  );
  setselectedFiles(files);
  // const fileku = files.target.files[0];
  // console.log(fileku)
  

  }
  // console.log("isi form",date_start);

  const handleSubmit = () => {
      const form = new FormData();
      form.append("emission_data",selectedFiles[0]);
      form.append("date_start",date_start);
      form.append("date_end",date_end);
      form.append("file_link","foo.bar");
      var headers_upload = {
          headers: {
          Authorization: 'Bearer ' + user_token,
          'Content-Type': 'multipart/form-data',
          'Accept':'application/json, text/plain, */*',
          },
      }
      DataCollectionService.create(form , headers_upload
      )
      .then((response) => {
          // console.log(response);
          Swal.fire(
              'Saved!',
              'Your data saved!',
              'success' 
            )
          navigate("/data-collection");
        })
        .catch((error) => {
          // setError(error.response.data)
          // console.log(error.response.data);
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              html:
              '<b>'+error.response.data.message+'</b> </br> ' +
              // '<a href="//sweetalert2.github.io">links</a> ' +
              error.response.data.error,
              confirmButtonText: 'OK',
              confirmButtonColor: "LightSeaGreen"
              // text: error.response.data.message+" ! </br>" + error.response.data.error,
              // footer: error.response.data.error
            })
        });
  }
  // console.log("File Kita : ",selectedFiles[0])
  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }   
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Data Collection" breadcrumbItem="Add Collection" /> */}
          <Row>
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">Data Collection</h4>
              <div className="page-title-right">
                <Breadcrumb className="m-0" >
                  <BreadcrumbItem>
                    <Link to={'/data-collection'}>
                    Data Collection
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    Add Data
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </div>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap align-items-center mb-4">
                    <h5 className="card-title me-2">Upload Files for Emission Collection</h5>
                    <div className="ms-auto">
                        <div>
                            <a
                                href="https://docs.google.com/spreadsheets/d/1s_933ttsZ0sF2LWlKzAlpS_-wbG_tyujzHSlsnyLzG8/edit?usp=sharing" target="_blank" rel="noopener noreferrer"
                                className="btn btn-soft-success btn-sm"
                            >
                                <i className="bx  bx-download me-1"></i> Download Template Files
                            </a>
                        
                        </div>
                    </div>
                  </div>
                    <Form
                        action=""
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                          }}
                    >
                    <Row className="align-items-center">
                        <Col xl={6}>
                            <div className="mb-3">
                                <Label htmlFor="example-date-input" className="form-Label">Start Date</Label>
                                <Input 
                                    className="form-control" type="date"  id="example-date-input"
                                    onChange={(e) => setDateStart(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className="mb-3">
                                <Label htmlFor="example-date-input" className="form-Label">End Date</Label>
                                <Input 
                                    className="form-control" type="date" id="example-date-input"
                                    onChange={(e) => setDateEnd(e.target.value)}
                                />
                            </div>
                        </Col>
                        
                    </Row>
                    <Row className="align-items-center">
                        <Col xl={12}>
                        {/* <input ref={filenya} className="form-control" type="file" id="formFile" /> */}

                        {/* <input type="file" className="form-control" name="upload_file" onChange={handleInputChange} /> */}
                        <Dropzone
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles);
                            }}
                            >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                </div>
                                </div>
                            )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f: any, i: number) => {
                                return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                >
                                    <div className="p-2">
                                    <Row className="align-items-center">
                                        
                                        <Col>
                                        <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                        >
                                            {f.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                        </p>
                                        </Col>
                                    </Row>
                                    </div>
                                </Card>
                                );
                            })}
                        </div>
                        </Col>
                    </Row>
                    {/* <br /> */}
                    <Row>
                        <Col xl={12}>
                            <div className="text-center mt-3">
                                {/* <Link to="/data-collection/detail">
                                    <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                    >
                                        Send Files
                                    </button>
                                </Link> */}
                                <Button type="submit"  className="btn btn-success btn-sm" >
                                    Upload File
                                </Button>
                                
                            </div>
                            
                        </Col>
                    </Row>
                    
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCollection;