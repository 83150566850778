import React, {useEffect, useMemo, useState} from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Modal,
  Input,
  Label,
 } from "reactstrap";
 import ReactPaginate from "react-paginate"; 
 import { Link, useNavigate } from "react-router-dom";
 import Swal from 'sweetalert2';
 import IndustrialService from '../../api/service/IndustrialService';
 import TenantService from '../../api/service/TenantService';
 import UserService from "../../api/service/UserService";
 import GroupService from "../../api/service/GroupService";
import {useParams} from 'react-router-dom';
 
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb-new";


const PageStarter = () => {
  document.title = "User Management | Carbon Platform";
  const initGroup = {
    id: "",
    name: "",
    industrial_park_id: "",
    tenant_id: "",
    type: "",
    level: ""
  }
  const initIndustrial = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: 0
  }
  const initTenant = {
    id: "",
    name: "",
    legal_name: "",
    head_count: 0,
    location_latitude: 0,
    location_longitude: 0,
    location_country: "",
    location_area_ha: null,
    business_sector: "",
    industrial_park_id: ""
  }
  const initUserDetail = {
    id: "",
    full_name: "",
    username: "",
    group_id: "",
    email: "",
    group_detail: initGroup,
    industrial_park_detail: initIndustrial,
    tenant_detail: initTenant
  }
  const initCreate = {
    id: "",
    full_name: "",
    username: "",
    group_id: "",
    email: "",
    group_detail: initGroup,
    industrial_park_detail: {
      id:"",
    },
    tenant_detail: {
      id:"",
    }
  }
  // Data
  const [UserList, setUserList] = useState([initUserDetail]);
  const [Industrial, setIndustrial] = useState([initIndustrial]);
  const [Tenant, setTenant] = useState([initTenant]);
  const [Group, setGroup] = useState([initGroup]);
  const [FilterTenant, setFilterTenant] = useState([initTenant]);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [selectedIndustialPark, setSelectedIndustialPark] = useState("");
  const [selectedTenant, setSelectedTenant] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [status_role, setstatus_role] = useState(false)
  const [status_save, setstatus_save] = useState(false)

  const {id} = useParams();
  const navigate = useNavigate();

  const type = [
    {
      alias_name:"industrial_park",
      name: "Industrial Park"
    }, 
    // {
    //   alias_name:"Tenant",
    //   name: "Tenant"
    // }
  ];
  useEffect(()=>{

    UserService.getId(id)
    .then(res=>{
      setbodyAsset(res.data.data)
      setSelectedIndustialPark(res.data.data.group_detail.industrial_park_id)
      setSelectedTenant(res.data.data.group_detail.tenant_id)
      setSelectedType(res.data.data.group_detail.type)

    }).catch(err => console.log(err));

    IndustrialService.getAll()
    .then(res=>{
      setIndustrial(res.data.data)
    }).catch(err => console.log(err));

    TenantService.getAll().then(res => {
      setTenant(res.data.data);
    }).catch(err => console.log(err));


  },[])

  // Filter Tenant
  // console.log(selectedIndustialPark)
  var tableData1 = Tenant;
  useEffect(() => {
    // console.log("park ids : ", bodyAsset)

    if (selectedIndustialPark) {
      tableData1 = Tenant.filter((data)=>
        data.industrial_park_id==selectedIndustialPark
      )

      setFilterTenant(tableData1)
    }
    else{
      setFilterTenant(tableData1)
    }

  }, [selectedIndustialPark, tableData1])

  // Get Group 
  useEffect(() => { 
    if (selectedIndustialPark!="" && selectedTenant!="" && selectedType!="") {
      setstatus_role(true);
    }else{
      setstatus_role(false);

    }
    if (status_role==true && bodyAsset.email!="" && bodyAsset.full_name!=""&& bodyAsset.username!="" && bodyAsset.group_id!="" ) {
      setstatus_save(true)
    }else {
      setstatus_save(false)
    }
    GroupService.getAll(selectedType, selectedIndustialPark, selectedTenant)
    .then(res=>{
      setGroup(res.data.data)
    }).catch(err => console.log(err));

  }, [selectedType, selectedIndustialPark, selectedTenant, status_role, bodyAsset.email,bodyAsset.username, bodyAsset.full_name, bodyAsset.group_id,])

  const save = ()=> {
    var data = {
      full_name: bodyAsset.full_name,
      username: bodyAsset.username,
      group_id:bodyAsset.group_id,
      email:bodyAsset.email
    }
    UserService.update(id,data)
    .then(response => {
      // swal("Saved!", "Your data saved!", "success");
      // Swal.fire({
      //   position: 'center',
      //   icon: 'success',
      //   title: 'Your data saved!',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
      Swal.fire("Saved!", "Your data succes Updated!", "success");
      navigate("/user-management");
      // setbodyAsset(initCreate);
      // setmodal_xlarge(false)
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User Management" link_title="/user-management" breadcrumbItem="Edit User" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Full Name</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="full_name"
              name="full_name"
              value={bodyAsset.full_name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Username</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="username"
              name="username"
              value={bodyAsset.username}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Email</Label>
            <Input 
              className="form-control" 
              type="email" 
              id="email"
              name="email"
              value={bodyAsset.email}
              onChange={handleInputChange}
              />
          </div>
          
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Industrial Park</Label>
            <select 
              value={selectedIndustialPark} 
              // onChange={handleInputChange}
              onChange={({ currentTarget }) => setSelectedIndustialPark(currentTarget.value,)}
              placeholder="Industrial Park"
              // id="industrial_park_detail"
              // name="industrial_park_detail"
              className="form-select">
                  <option value="">Select Industrial Park</option>
                  {
                    Industrial?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw.id}
                        >{raw.legal_name}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Tenant</Label>
            <select 
              value={selectedTenant} 
              onChange={({ currentTarget }) => setSelectedTenant(currentTarget.value,)}
              placeholder="Tenant"
              id="tenant_detail"
              name="tenant_detail"
              className="form-select">
                  <option value="">Select Tenant</option>
                  {
                    FilterTenant?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw.id}
                        >{raw.legal_name}
                      </option>

                    ))
                  }
            </select>
          </div>

          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Type</Label>
            <select 
              value={selectedType} 
              onChange={({ currentTarget }) => setSelectedType(currentTarget.value,)}
              placeholder="type"
              id="type"
              name="type"
              className="form-select">
                  <option value="">Select Type</option> 
                  {
                    type?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw.alias_name}
                        >{raw.name}
                      </option>

                    ))
                  }
            </select>
          </div>

          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Role</Label>
            <select 
              value={bodyAsset.group_id} 
              onChange={handleInputChange}
              placeholder="Group"
              id="group_id"
              name="group_id"
              disabled={status_role ? false : true}
              className="form-select">
                  <option value="">Select Group</option>
                  {
                    Group?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw.id}
                        >{raw.level}
                      </option>

                    ))
                  }
            </select>
          </div>
          
          <div className="modal-footer">
            <Link to={"/user-management"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            {
              <button 
                type="button"
                disabled={status_save ? false : true}
                onClick={() => {
                  save();
                }}
                className="btn btn-success">
                Save
              </button>
              }
            
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PageStarter;
