import React , {useState, useEffect} from "react";
import { 
  Container,
  Row,
  Col,
  Card
   } from "reactstrap";
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";

import { isNull } from "lodash";
import EmbeddedLinkTenant from "../../../api/service/EmbeddedLinkTenant";
import error from "../../../assets/images/error-img.png";

const ReportMonth = () => {
  // const initChart = {
  //   id:"",
  //   link:"",
  //   chart_type:"",
  //   industrial_park_id:""
  // }
  const initEmbeddedLink = {
    id: "",
    name: "",
    link: "",
    page_destination: "",
    updated_by: "",
    updated_at: "",
    tenant_id: "",
    tenant_name: ""
  }

  document.title = "Monthly Report | Carbon Platform";
  const [customMR, setcustomMR] = useState("");
  // const [chart, setchart] = useState([initChart]);
  // const [embedchart, setembedchart] = useState("");
  const [embedchartTenant, setembedchartTenant] = useState([initEmbeddedLink]);
  var tenant_user = localStorage.getItem('tenant_id') || "";
  
  const [showName, setShowName] = useState(false);
  const [showCustom1, setShowCustom1] = useState(false);
  const url = process.env.REACT_APP_BE_CHART;
  const [delete_status, setdelete_status] = useState(0);
  const [status_save, setstatus_save] = useState(0);

  // localStorage.setItem('CS1','default');
  useEffect(() => {
    EmbeddedLinkTenant.getAll(tenant_user,"Monthly","")
    .then(res => {
      try {
        setembedchartTenant(res.data.data)
        
      } catch (error) {
        setembedchartTenant([]);
      }
      
    }).catch(err => console.log(err));
    // memanggil API untuk mengambil data todos
    //Call API
    // var param = {
    //   type : "monthly",
    //   industrial_park_id:""
    // }
    // EmbedLink.getAll(param.type, param.industrial_park_id)
    // .then(res => {
    //   // console.log("res data", res)
    //   if (res.data.data.length>0) {
    //     setchart(res.data.data);
    //     setembedchart(res.data.data[0]["link"])
    //   }
     
    // }).catch(err => console.log(err));
    
    // console.log("chart",chart[0].chart_type)
    //End API
  }, [ delete_status, status_save]);
  // console.log(embedchartTenant)
  // console.log(delete_status)
  // const save = ()=> {
    
  //   if (chart[0].id=="") {
  //     const data = {
  //       link:embedchart,
  //       chart_type:"monthly",
  //       industrial_park_id:localStorage.getItem('industrial_id')
  //     }
  //     EmbedLink.create(data)
  //     .then(response => {
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Your data saved!',
  //         showConfirmButton: false,
  //         timer: 1500
  //       })
  //       setstatus_save(status_save+1);
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  //   }else{
  //     const data = {
  //       link:embedchart,
  //       chart_type:"monthly",
  //     }
  //     EmbedLink.update(chart[0].id,data)
  //     .then(response => {
  //       // swal("Saved!", "Your data saved!", "success");
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Your data Updated!',
  //         showConfirmButton: false,
  //         timer: 1500
  //       })
  //     setstatus_save(status_save+1);    

  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  //   }
  // }
  
  // const handleReset = (id:string) => {
  //   Swal.fire({
  //     title: 'Delete Confirmation',
  //     text: "Are you sure Reset this Chart!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, Reset it!'
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       EmbedLink.remove(id)
  //       .then(response => {
  //         Swal.fire(
  //           'Reseted!',
  //           'Your Chart has been Reseted!',
  //           'success'
  //         )
  //         setdelete_status(delete_status+1);
  //       })
  //       .catch(e => {
  //         console.log(e);
  //       });
        
  //     }
  //   })
  //   window.location.reload();
    
  // }; 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Report" link_title="/report" breadcrumbItem="Monthly Report" />
          <Row>
            <div>
              {
                embedchartTenant.length>0 ?
                  <iframe
                  width="100%"
                  height="920"
                  seamless
                  frameBorder="0"
                  scrolling="auto"
                  src={`${embedchartTenant[0].link}`}

                >
                </iframe>
                :
                <>
                  <Col lg={12}>
                    <div className="text-center mb-5">
                      <h1 className="display-1 fw-semibold">
                        4<span className="text-primary mx-2">0</span>4
                      </h1>
                      <h4 className="text-uppercase">Sorry, the report is not configured yet</h4>
                      <div className="mt-3 ">
                        <Row>
                        <Col lg={4}>
                        </Col>
                        <Col lg={4}>
                          <Card className="pt-3 pb-3 border border-primary text-center text-success fw-medium display-8">
                            Request your admin to set it up
                          </Card>
                        </Col>
                        </Row>
                        
                      </div>
                    </div>
                  </Col>
                  <div className="row justify-content-center">
                      <div className="col-md-10 col-xl-8">
                        <div>
                          <img src={error} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                </>
              }
              
            </div>
            
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportMonth;
