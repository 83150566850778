import React, { useEffect, useMemo, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import swal from 'sweetalert';

import { 
  Col,
  Container,
  Row,
  Input,
  Label, } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb-new";
import {useParams} from 'react-router-dom';


import AssetsListService from '../../../api/service/AssetsListService';
import EmissionFactorService from '../../../api/service/EmissionFactorService';

const EditEmissionFactor = () => {
  document.title = "Assets List | Carbon Platform";
  const initArea = {
    id: "",
    name: "",
    type: "",
  }
  const initCreate1 = {
    name:"",
    type:"",
    emission_factor_value:0,
    emission_factor_unit:"",
    reference:"",
  }
  const initCreate = {
    name:"",
    type:"",
    emission_factor_value:0,
    emission_factor_unit:"",
    reference:"",
    reference_link:""
  }
  const [area, setArea] = useState([initArea]);
  const [types, setTypes] = useState([]);

  // const [bodyAsset1, setbodyAsset1] = useState(initCreate);
  const [bodyAsset, setbodyAsset] = useState(initCreate);
  const [bodyAsset_rl, setbodyAsset_rl] = useState("");
  const {id} = useParams();
  const navigate = useNavigate();
  const factor_unit = ["GWP","kgN2O/kgNremoved","kgCH4/kgCODremoved","kgCH4/kgBODremoved","tCO2/L","tCO2/kWh","tCO2eq/kg"];

  useEffect(() => {
    AssetsListService.getArea()
    .then(res => {
      // console.log(res.data)
      setArea(res.data.data);
    }).catch(err => console.log(err));

    AssetsListService.getAssetTypes()
    .then(res => {
      setTypes(res.data.data);
      
    }).catch(err => console.log(err));
    
    EmissionFactorService.getId(id)
    .then(response => {
      
      setbodyAsset(response.data.data)
      
    })
    .catch(e => {
      console.log(e);
    });
  }, [])
  // const getreference = bodyAsset.reference.split(";")
  // bodyAsset["reference"] = getreference[0];
  // setbodyAsset(prevState => ({
  //   ...prevState,
  //   reference: getreference[0],
  //   reference_link :getreference[1]
  // }))
  // var data_rl = getreference[1]
  // setbodyAsset_rl(getreference[1])
  // bodyAsset.reference_link = getreference[1];
  // console.log(bodyAsset.reference_link)


  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setbodyAsset({ ...bodyAsset, [name]: value });
  };
  const save = ()=> {
    var data = {
      name : bodyAsset.name,
      type : bodyAsset.type,
      emission_factor_value: bodyAsset.emission_factor_value,
      emission_factor_unit: bodyAsset.emission_factor_unit,
      reference :bodyAsset.reference,
      reference_link :bodyAsset.reference_link
      
    }
    console.log(data)
    EmissionFactorService.update(id,data)
    .then(response => {
      swal("Saved!", "Your data succes Updated!", "success");
      navigate("/emission-factor");
    })
    .catch(e => {
      console.log(e);
    });
    
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Emission Factor" link_title="/emission-factor" breadcrumbItem="Edit Emission Factor" />
          <hr />
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Name</Label>
            <Input 
              className="form-control" 
              type="text" 
              id="name"
              name="name"
              value={bodyAsset.name}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Type</Label>
            <select 
              value={bodyAsset.type} 
              onChange={handleInputChange}
              id="type"
              name="type"
              className="form-select">
                  <option value=""></option>

                  {
                    types?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw}
                        // onChange={Handlefilter}
                        >{raw}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Emission Factor Value</Label>
            <Input 
              className="form-control" 
              type="number" 
              id="emission_factor_value"
              name="emission_factor_value"
              value={bodyAsset.emission_factor_value}
              onChange={handleInputChange}
              />
          </div>
          <div className="mb-3">
            <Label htmlFor="example-text-input" className="form-Label">Emission Factor Unit</Label>
            <select 
              value={bodyAsset.emission_factor_unit} 
              onChange={handleInputChange}
              id="emission_factor_unit"
              name="emission_factor_unit"
              className="form-select">
                  <option value=""></option>

                  {
                    factor_unit?.map((raw, index)=>(
                      <option 
                        key={index} 
                        value={raw}
                        // onChange={Handlefilter}
                        >{raw}
                      </option>

                    ))
                  }
            </select>
          </div>
          <div className="mb-3">
            <Row>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">Referances</Label>
                <Input 
                  className="form-control" 
                  type="text" 
                  id="reference"
                  name="reference"
                  value= {bodyAsset.reference}
                  onChange={handleInputChange}
                  />
              </Col>
              <Col>
                <Label htmlFor="example-text-input" className="form-Label">References Link</Label>
                <Input 
                  className="form-control" 
                  type="text" 
                  // id="reference_link"
                  // name="reference_link"
                  value={bodyAsset.reference_link}
                  onChange={handleInputChange}
                  />
              </Col>
              
            </Row>
          </div>
          <div className="text-sm-end">
            <Link to={"/emission-factor"} className="btn btn-outline-secondary waves-effect">
              Back
            </Link>
            &nbsp;
            &nbsp;
            <button 
              type="button"
              onClick={() => {
                save();
              }}
              className="btn btn-success">
              Update
            </button>
          </div>
          <br />
              
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditEmissionFactor;
